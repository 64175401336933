import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    header: React.CSSProperties;
    headerMedium: React.CSSProperties;
    subHeader: React.CSSProperties;
    valueName: React.CSSProperties;
    valueText: React.CSSProperties;
    description: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    header?: React.CSSProperties;
    headerMedium?: React.CSSProperties;
    subHeader?: React.CSSProperties;
    valueName?: React.CSSProperties;
    valueText?: React.CSSProperties;
    description: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    header: true;
    headerMedium: true;
    subHeader: true;
    valueName: true;
    valueText: true;
    description: true;
  }
}

export const wrongCredentialsColor = '#f9e1dc';
const fontFamily = `"Roboto", "Helvetica", "Arial", sans-serif`;

export const theme = createTheme({
  typography: {
    header: {
      fontFamily: fontFamily,
      fontWeight: 'bold',
      fontSize: 30,
      color: '#101828',
    },
    headerMedium: {
      fontFamily: fontFamily,
      fontWeight: 'inherit',
      fontSize: 24,
      color: '#101828',
    },
    subHeader: {
      fontFamily: fontFamily,
      fontWeight: 'normal',
      fontSize: 20,
      color: '#101828',
    },
    valueName: {
      fontFamily: fontFamily,
      fontWeight: 'bold',
      fontSize: 14,
      color: '#344054',
    },
    valueText: {
      fontFamily: fontFamily,
      fontWeight: 'normal',
      fontSize: 14,
      color: '#475467',
    },
    description: {
      fontFamily: fontFamily,
      fontWeight: 'bold',
      fontSize: 10,
      color: '#475467',
    },
  },

  palette: {
    primary: {
      main: '#11AEA9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#477ac7',
    },
  },
});
