import { apiAuthFetch, API_ADDRESS } from '../apiFetch'
import { handleErrors } from '../errorHandler'
import { RoleItemDto } from './contracts/RoleItemDto'
import { RoleDto } from './contracts/RoleDto'
import { UserDto } from './contracts/UserDto'
import { RightDto } from './contracts/RightDto'

const ROLES_API_ADDRESS = `${API_ADDRESS}/api/administration/roles`

export class RolesApi {
  async getRoles() {
    return apiAuthFetch()
      .get(ROLES_API_ADDRESS)
      .then(handleErrors)
      .then<RoleItemDto[]>((res) => res.json())
  }

  async getRole(roleId: number | string) {
    return apiAuthFetch()
      .get(`${ROLES_API_ADDRESS}/${roleId}`)
      .then(handleErrors)
      .then<RoleDto>((res) => res.json())
  }

  async addUsers(roleId: number | string, users: string[]) {
    return apiAuthFetch()
      .post(`${ROLES_API_ADDRESS}/${roleId}/users`, { users })
      .then(handleErrors)
      .then<UserDto[]>((res) => res.json())
  }

  async removeUser(roleId: number | string, userId: string) {

    return apiAuthFetch().delete(`${ROLES_API_ADDRESS}/${roleId}/users/${userId}`).then(handleErrors)
  }

  async saveRights(roleId: number | string, rights: number[]) {

    return apiAuthFetch()
      .put(`${ROLES_API_ADDRESS}/${roleId}/rights`, { rights })
      .then(handleErrors)
      .then<RightDto[]>((res) => res.json())
  }

  async createRole(roleName: string) {
    return apiAuthFetch()
      .post(`${ROLES_API_ADDRESS}`, { name: roleName })
      .then(handleErrors)
      .then<RoleDto>((res) => res.json())
  }

  async updateRole(roleId: number | string, data: { name: string }) {
    return apiAuthFetch().put(`${ROLES_API_ADDRESS}/${roleId}`, data).then(handleErrors)
  }

  async deleteRole(roleId: number | string) {
    return apiAuthFetch().delete(`${ROLES_API_ADDRESS}/${roleId}`).then(handleErrors)
  }
}

const rolesApi = new RolesApi()

export default rolesApi
