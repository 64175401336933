import { handleErrors } from '../errorHandler'
import { API_ADDRESS, apiAuthFetch } from '../apiFetch'

const RIGHTS_API_ADDRESS = `${API_ADDRESS}/api/administration/statuses`

export class StatusesApi {
  async getStatuses() {
    return apiAuthFetch()
      .get(RIGHTS_API_ADDRESS)
      .then(handleErrors)
      .then<string[]>((res) => res.json())
  }

}

const statusesApi = new StatusesApi()

export default statusesApi
