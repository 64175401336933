import { nanoid } from 'nanoid';

export class BaseNode {
  id: string;
  value: string;
  children: BaseNode[];

  constructor(id: string, value: string) {
    this.id = id;
    this.value = value;
    this.children = [];
  }

  addChild(child: BaseNode) {
    this.children.push(child);
  }
}

export class LeafNode extends BaseNode {
  value: any;

  constructor(id: string, value: any) {
    super(id, '');
    this.value = value;
  }
}

export class Tree {
  root: BaseNode = new BaseNode(nanoid(), '');

  addNode(id: string, path: string, value: any) {
    const folders = path.split('\\');
    let currentNode = this.root;

    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      let childNode = currentNode.children.find(
        (node) => node instanceof BaseNode && node.value === folder,
      );

      if (!childNode) {
        childNode = new BaseNode(folder + nanoid(), folder);
        currentNode.addChild(childNode);
      }

      currentNode = childNode;
    }

    const leafNode = new LeafNode(id, value);
    currentNode.addChild(leafNode);
  }
}
