import { handleErrors } from '../errorHandler'
import { API_ADDRESS, apiAuthFetch } from '../apiFetch'

const RIGHTS_API_ADDRESS = `${API_ADDRESS}/api/administration/groups`

export class GroupsApi {
  async getGroups() {
    return apiAuthFetch()
      .get(RIGHTS_API_ADDRESS)
      .then(handleErrors)
      .then<string[]>((res) => res.json())
  }
}

const groupsApi = new GroupsApi()

export default groupsApi
