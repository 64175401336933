import { FC, useEffect, useState } from 'react';
import ViewerNotification from './ViewerNotification';

interface Props {
  url: string;
}

const PdfViewer: FC<Props> = ({ url }) => {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setPdfBlob(null);
    setError(null);

    fetch(url)
      .then((response) => {
        if (!response.ok && response.status === 404) {
          throw new Error(
            'Печатная форма для данного документа не подготовлена.\nДля подготовки печатной формы обратитесь к PDM администратору',
          );
        }

        if (!response.ok) {
          throw new Error('При загрузке данных возникли ошибки');
        }

        return response.blob();
      })
      .then((blob) => {
        if (blob.type === 'application/pdf') {
          setPdfBlob(blob);
          setError(null);
        } else {
          setPdfBlob(null);
          setError('Ошибка типа файла');
        }
      })
      .catch((error) => {
        setPdfBlob(null);
        setError(error.message);
      });
  }, [url]);

  if (error) {
    return <ViewerNotification text={error} />;
  }

  if (pdfBlob) {
    return (
      <object
        data={URL.createObjectURL(pdfBlob)}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <ViewerNotification
          text={'Отображение документа не поддерживается браузером'}
        />
      </object>
    );
  }
  return <div>Loading...</div>;
};

export default PdfViewer;
