import * as HttpStatus from 'http-status-codes'

export async function handleErrors(response: Response, message?: string) {
  if (!response.ok) {
    const error = new Error()
    const code = response.status
    if (code === 401) {
      window.location.replace('/renew')
    } else {
      error.name = `${response.status.toString()} ${HttpStatus.getStatusText(response.status)}`
      error.message = message || (await response.text()) || HttpStatus.getStatusText(response.status)
      throw error
    }
  }

  return response
}
