import { FunctionComponent, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Box, Grid, Typography, Button, Stack } from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { checkPermission } from '../../user/meSlice'
import { Permissions_Administration_Edit } from '../../../infrastructure/types/permissions'
import { loadRights } from '../rightsActions'
import {
  selectRights, showAddRightDialog, hideActionError, hidePageError, setAddRightDialogType, RestrictionDialogType,
} from '../rightsSlice'
import RightsList from '../components/rights/RightsList'
import UpdateRestrictionDialog from '../components/rights/UpdateRestrictionDialog'
import ErrorDialog from '../../../components/ErrorDialog'
import CreateRestrictionDialog from '../components/rights/CreateRestrictionDialog'
import { getPaths } from '../pathsActions'
import ErrorBox from '../../../components/ErrorBox'
import { getGroups } from '../groupsActions'
import { getStatuses } from '../statusesActions'
import { getCertificates } from '../certificatesActions'

const RightsListPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const rightsListState = useAppSelector(selectRights)

  useEffect(() => {
    dispatch(loadRights())
    dispatch(getPaths())
    dispatch(getGroups())
    dispatch(getStatuses())
    dispatch(getCertificates())
  }, [dispatch])

  const canAdmin = useAppSelector(
    (root: RootState) =>
      checkPermission(root, Permissions_Administration_Edit),
  )

  const handleAddRight = (type: RestrictionDialogType) => {
    if (type === RestrictionDialogType.project) {
      dispatch(setAddRightDialogType(RestrictionDialogType.project))
    }
    if (type === RestrictionDialogType.group) {
      dispatch(setAddRightDialogType(RestrictionDialogType.group))
    }
    if (type === RestrictionDialogType.status) {
      dispatch(setAddRightDialogType(RestrictionDialogType.status))
    }
    if (type === RestrictionDialogType.certificate) {
      dispatch(setAddRightDialogType(RestrictionDialogType.certificate))
    }

    dispatch(showAddRightDialog())
  }

  return (
    <Layout open={false} setOpen={
      () => {
      }
    } showDrawerButton={false}>
      <Box sx={{ width: '75%', display: 'flex' }} mt={10} mr={'auto'} ml={'auto'}>
        <Stack sx={{ width: '100%' }} direction="column">
          {rightsListState.status === 'idle' &&
            <Grid container direction="row" spacing={1}>
              <Grid item xs>
                <Typography variant="h4">Права</Typography>
              </Grid>
              <Grid item>
                {canAdmin && (
                  <Stack direction={'column'} spacing={1}>
                    <Button variant="outlined" onClick={() => handleAddRight(RestrictionDialogType.project)}>
                      Добавить разрешение для раздела
                    </Button>
                    <Button variant="outlined" onClick={() => handleAddRight(RestrictionDialogType.group)}>
                      Добавить разрешение для категорий
                    </Button>
                    <Button variant="outlined" onClick={() => handleAddRight(RestrictionDialogType.status)}>
                      Добавить разрешение для статуса
                    </Button>
                    <Button variant="outlined" onClick={() => handleAddRight(RestrictionDialogType.certificate)}>
                      Добавить разрешение для сертификата
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>
          }
          <RightsList/>
        </Stack>

      </Box>
      {rightsListState.pageError && (
        <ErrorBox
          error={rightsListState.pageError}
        >
          <Button
            variant="contained"
            sx={{ maxWidth: '75%' }}
            onClick={() => {
              dispatch(hidePageError())
              dispatch(loadRights())
              dispatch(getPaths())
              dispatch(getGroups())
              dispatch(getStatuses())
            }}>
            Попробовать снова
          </Button>
        </ErrorBox>
      )}
      <ErrorDialog
        open={rightsListState.actionError !== undefined}
        title={rightsListState.actionError?.name || 'Error'}
        text={rightsListState.actionError?.message ?? ''}
        onOk={() => dispatch(hideActionError())}
      />
      <CreateRestrictionDialog open={rightsListState.openAddRightDialog}/>
      <UpdateRestrictionDialog open={rightsListState.openEditRightsDialog}/>
    </Layout>
  )
}

export default RightsListPage
