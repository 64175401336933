import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { type FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { downloadDialogState } from './downloadDialogSlice'
import { close } from './downloadDialogSlice'
import DownloadFileItem from '../../components/DownloadFileItem'
import { selectTree } from '../projects/projectsSlice'
import _ from 'lodash'
import { padWithLeadingZeros } from '../../helpers/stringHelper'

const DownloadDialog: FC = (props) => {
  const dispatch = useAppDispatch()
  const dialogState = useAppSelector(downloadDialogState)
  const treeState = useAppSelector(selectTree)

  const handleCloseClick = () => {
    dispatch(close())
  }

  const arrayForSort = [...dialogState.certificates]
  arrayForSort.sort((a, b) => {
    if (!a.revision || !b.revision) {
      return 0
    }
    const va = +a.revision
    const vb = +b.revision
    if (va < vb) {
      return -1
    }
    if (va > vb) {
      return 1
    }
    return 0
  })
  const groups = _.groupBy(arrayForSort, (c) => +c.revision)

  return (
    <Dialog
      open={dialogState.open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '400px!important',
          maxHeight: '650px!important',
        },
      }}
    >
      <DialogTitle>Сертифицированные документы</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={dialogState.status === 'prepare'}>
          <Grid container>
            {dialogState.fetchStatus === 'pending' && (
              <Stack spacing={3}>
                <Skeleton width={350}/>
                <Skeleton width={350}/>
                <Skeleton width={350}/>
              </Stack>
            )}
          </Grid>
        </Collapse>
        <Collapse in={dialogState.status === 'completed'}>
          <Grid container>
            <Grid item xs={12}>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
                {dialogState.certificates &&
                  _.map(groups, (documents, group) => {
                    const certificates = documents.reduce(
                      function (
                        concat,
                        current,
                      ) {
                        if (concat === '') {
                          return `${current.certificate}`
                        } else {
                          return `${concat};    ${current.certificate}`
                        }
                      },
                      '')

                    const timestamp = documents.at(0)?.timestamp ?? ''

                    const rev = padWithLeadingZeros(+group, 2)

                    return (
                      <DownloadFileItem
                        projectId={treeState.activeProject?.data.fileId ?? 0}
                        documentId={treeState.activeDocument?.data.fileId ?? 0}
                        fileName={`certificated_document_${certificates}_rev${rev}.pdf`}
                        certLabel={`${certificates}`}
                        dataLabel={`Редакция: ${rev} Дата: ${timestamp}`}
                        revision={rev}
                      />
                    )
                  })}

                {!dialogState.certificates && (
                  <Typography color="error">
                    Сертифицированных документов не найдено
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={dialogState.status === 'error'}>
          <Typography color="error">
            {dialogState.error?.message ??
              'Произошла ошибка во время получения данных. Повторите операцию позже.'}
          </Typography>
        </Collapse>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Collapse in={true}>
          <Button variant="outlined" color="primary" onClick={handleCloseClick}>
            Закрыть
          </Button>
        </Collapse>
      </DialogActions>
    </Dialog>
  )
}

export default DownloadDialog
