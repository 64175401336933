import { FunctionComponent } from 'react'
import {
  Box,
  Grid,
  Skeleton,
  Typography, Stack, Button,
} from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import ErrorDialog from '../../../components/ErrorDialog'
import Layout from '../../../components/Layout/Layout'
import UserInfo from '../components/users/UserInfo'
import { selectSignedUser, selectMe, hidePageError, hideActionError } from '../../user/meSlice'
import ErrorBox from '../../../components/ErrorBox'
import { loadMe } from '../../user/meActions'

const UserPage: FunctionComponent = () => {
  const user = useAppSelector(selectSignedUser)
  const meState = useAppSelector(selectMe)
  const dispatch = useAppDispatch()

  const loading = meState.status === 'loading'
  const saving = meState.status === 'saving'

  return (
    <Layout open={false} setOpen={
      () => {
      }
    } showDrawerButton={false}>

      <Box sx={{ width: '75%', display: 'flex' }} mt={10} mr={'auto'} ml={'auto'}>
        <Stack sx={{ width: '100%' }} direction="column">
          {meState.status === 'idle' &&
            <Box mb={2}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <Typography variant="h5">
                    {loading || saving ? (
                      <Skeleton width={50}/>
                    ) : (
                       `Пользователь ${user?.userName ?? ''}`
                     )}
                  </Typography>
                </Grid>
                <Grid item xs/>
              </Grid>
            </Box>
          }
          <UserInfo/>
        </Stack>
      </Box>
      {meState.pageError && (
        <ErrorBox
          error={meState.pageError}
        >
          <Button
            variant="contained"
            sx={{ maxWidth: '75%' }}
            onClick={() => {

              dispatch(hidePageError())
              dispatch(loadMe())
            }
            }
          >
            Попробовать снова
          </Button>
        </ErrorBox>
      )}
      <ErrorDialog
        open={meState.actionError !== undefined}
        title={meState.actionError?.name || 'Error'}
        text={meState.actionError?.message ?? ''}
        onOk={() => dispatch(hideActionError())}
      />
    </Layout>
  )
}

export default UserPage
