import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from '@mui/material'
import lodash from 'lodash'
import {
  type FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { UserListItem, selectRoleUsersDialog } from '../../selectRoleUsersSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { loadUsers } from '../../userAction'
import ErrorBox from '../../../../components/ErrorBox'
import PersonIcon from '@mui/icons-material/Person'

interface Props {
  open: boolean
  exists: string[]
  onCancel: () => void
  onOk: (selected: UserListItem[]) => void
}

const SelectUserDialog: FunctionComponent<Props> = (props) => {
  const [selected, setSelected] = useState<UserListItem[]>([])

  const dispatch = useAppDispatch()
  const dialogState = useAppSelector(selectRoleUsersDialog)

  useEffect(() => {
    setSelected([])

    if (props.open) {
      dispatch(loadUsers())
    }
  }, [dispatch, props.open])

  const handleSelect = (user: UserListItem) => {
    const index = selected.findIndex((u) => u.id === user.id)
    if (index >= 0) {
      setSelected(selected.filter((u) => u.id !== user.id))
    } else {
      setSelected([...selected, user])
    }
  }

  const renderContent = () => {
    if (dialogState.status === 'loading') {
      return (
        <List>
          {Array.from(Array(5).keys()).map((i) => {
            return (
              <ListItem
                key={i}
                divider
              >
                <ListItemAvatar>
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                  />
                </ListItemAvatar>
                <Box sx={{ width: '100%' }}>
                  <Skeleton/>
                </Box>
              </ListItem>
            )
          })}
        </List>
      )
    }

    if (dialogState.status === 'failed' && dialogState?.dialogError) {
      return (
        <ErrorBox
          error={dialogState.dialogError}
        >
          <Button variant="contained" onClick={() => dispatch(loadUsers())}>
            Попробовать снова
          </Button>
        </ErrorBox>
      )
    }

    return (
      <List>
        {lodash.sortBy(dialogState.users, ['userName']).map((u) => {
          return (
            <ListItem
              key={u.id}
              button
              selected={selected.find((s) => s.id === u.id) !== undefined}
              sx={{ selected: { color: (theme) => theme.palette.action.selected } }}
              onClick={() => {
                handleSelect(u)
              }}
            >
              <ListItemAvatar>
                <PersonIcon/>
              </ListItemAvatar>
              <ListItemText
                primary={u.userName}
                secondary={u.fullName}
                sx={{ wordBreak: 'break-word' }}
              />
            </ListItem>
          )
        })}
      </List>
    )
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Select user</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button
          onClick={props.onCancel}
          color="primary"
        >
          Отменить
        </Button>
        <Button
          disabled={selected.length === 0}
          onClick={() => {
            selected && props.onOk(selected)
          }}
          color="primary"
          variant="contained"
        >
          {selected.length > 0
           ? `Сохранить (${selected.length})`
           : 'Сохранить'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectUserDialog
