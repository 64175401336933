import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { UserDto } from '../../infrastructure/api/administration/contracts/UserDto'
import { loadRoles, createRole } from './roleActions'
import { RootState } from '../../app/store'

export interface RolesListState {
  status: 'idle' | 'loading' | 'failed';
  roles: RolesListItem[];
  createRoleDialogOpened: boolean;
  createRoleName?: string;
  pageError?: SerializedError;
  actionError?: SerializedError;
}

export interface RolesListItem {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  id: number;
  name: string;
  users: UserDto[];
}

const initialState: RolesListState = {
  status: 'idle',
  createRoleDialogOpened: false,
  roles: [],
}

export const rolesListSlice = createSlice(
  {
    name: 'roles-list',
    initialState,
    reducers: {
      setActionError: (state, action: PayloadAction<Error | undefined>) => {
        state.actionError = action.payload
      },
      setPageError: (state, action: PayloadAction<Error | undefined>) => {
        state.pageError = action.payload
      },
      setCreateRoleName: (state, action: PayloadAction<string | undefined>) => {
        state.createRoleName = action.payload
      },
      setCreateRoleDialog: (state, action: PayloadAction<boolean>) => {
        state.createRoleDialogOpened = action.payload
      },
    },
    extraReducers: (build) => {
      build.addCase(loadRoles.pending, (state, action) => {
        state.status = 'loading'
      })

      build.addCase(loadRoles.fulfilled, (state, action) => {
        state.status = 'idle'
        state.roles = action.payload.map((r) => {
          return {
            status: 'idle',
            id: r.id,
            name: r.name,
            users: r.users,
          } as RolesListItem
        })
      })

      build.addCase(loadRoles.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })

      build.addCase(createRole.pending, (state, action) => {
        state.status = 'loading'
      })

      build.addCase(createRole.fulfilled, (state, action) => {
        state.status = 'idle'
      })

      build.addCase(createRole.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })
    },
  })

export const {
               setActionError,
               setPageError,
               setCreateRoleDialog,
               setCreateRoleName,
             } = rolesListSlice.actions
export const selectRolesList = (state: RootState) => state.administration.roles.list

export default rolesListSlice.reducer
