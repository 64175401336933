import { handleErrors } from '../errorHandler'
import { API_ADDRESS, apiAuthFetch } from '../apiFetch'
import { RightDto } from './contracts/RightDto'
import { RestrictionDialogType } from '../../../features/administration/rightsSlice'

const RIGHTS_API_ADDRESS = `${API_ADDRESS}/api/administration/rights`

export class RightsApi {
  async getRights() {
    return apiAuthFetch()
      .get(RIGHTS_API_ADDRESS)
      .then(handleErrors)
      .then<RightDto[]>((res) => res.json())
  }

  async createRight(configuringParameter: string, description?: string, type?: RestrictionDialogType) {
    return apiAuthFetch()
      .post(`${RIGHTS_API_ADDRESS}/restrictions/`, { configuringParameter: configuringParameter, description: description, type: type })
      .then(handleErrors)
      .then<RightDto>((res) => res.json())
  }

  async updateRight(rightId: number, configuringParameter: string, description?: string) {
    return apiAuthFetch()
      .put(`${RIGHTS_API_ADDRESS}/restrictions/${rightId}`, { configuringParameter: configuringParameter, description: description })
      .then(handleErrors)
      .then<RightDto>((res) => res.json())
  }

  async deleteRight(rightId: number) {
    return apiAuthFetch()
      .delete(`${RIGHTS_API_ADDRESS}/restrictions/${rightId}`)
      .then(handleErrors)
      .then<RightDto>((res) => res.json())
  }
}

const rightsApi = new RightsApi()

export default rightsApi
