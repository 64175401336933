import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit'
import tree from '../features/projects/projectsSlice'
import project from '../features/projects/projectVariablesSlice'
import document from '../features/projects/documentVariablesSlice'
import { currentUser } from '../features/user/reducers'
import settings from '../features/settings/settingsSlice'
import downloadDialog from '../features/download/downloadDialogSlice'
import { administration } from '../features/administration/reducers'

export const store = configureStore(
  {
    reducer: {
      tree,
      variables: combineReducers(
        {
          project,
          document,
        }),
      currentUser,
      settings,
      downloadDialog,
      administration,
    },
  })

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
