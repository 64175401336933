import { Fragment, FunctionComponent, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'

import RoleUsersCard from './RoleUsersCard'
import RoleRightsCard from './RoleRightsCard'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import {
  selectEditRole, setEditName,
} from '../../roleSlice'
import { loadRole, saveChanges } from '../../roleActions'
import ErrorBox from '../../../../components/ErrorBox'
import { isEmptyOrSpaces } from '../../../../helpers/stringHelper'

const RoleInfo: FunctionComponent = () => {
  const { roleId } = useParams() as { roleId: string }
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const idle = roleState.status === 'idle'
  const loading = roleState.status === 'loading'
  const saving = roleState.status === 'saving'

  const cardError = isEmptyOrSpaces(roleState.editName)

  useEffect(() => {
    dispatch(loadRole(roleId))
  }, [roleId, dispatch])

  const handleSaveChanges = () => {
    dispatch(saveChanges({ roleId, name: roleState.editName }))
  }

  return (
    <Box mt={4}>
      <Container maxWidth="md">
        {(idle || loading || saving) && (
          <Fragment>
            <Paper>
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h3">
                      {loading ? (
                        <Skeleton width={100}/>
                      ) : (
                         <TextField
                           variant="outlined"
                           label="Название"
                           error={isEmptyOrSpaces(roleState.editName)}
                           value={roleState.editName}
                           onChange={(e) =>
                             dispatch(setEditName(e.target.value))
                           }
                         />
                       )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {(idle || saving) && (
                      <Button
                        variant="contained"
                        disabled={saving || cardError}
                        onClick={handleSaveChanges}
                      >
                        {saving ? (
                          <CircularProgress size={20}/>
                        ) : (
                           'Изменить имя'
                         )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box mt={3}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} lg={6}>
                  <RoleUsersCard/>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <RoleRightsCard/>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        )}
      </Container>
    </Box>
  )
}

export default RoleInfo
