import { FunctionComponent } from 'react'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'

import {
  Clear,
} from '@mui/icons-material'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import { selectEditRole } from '../../roleSlice'
import { removeUser } from '../../roleActions'
import PersonIcon from '@mui/icons-material/Person'

const RoleUsersList: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const handleRemoveUser = (userId: string) => {
    roleState.id &&
    dispatch(removeUser({ roleId: roleState.id, userId }))
  }

  if (roleState.status === 'loading') {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <ListItem key={i} divider>
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40}/>
              </ListItemAvatar>
              <Box sx={{ width: '100%' }}>
                <Skeleton/>
              </Box>
            </ListItem>
          )
        })}
      </List>
    )
  }

  return (
    <List>
      {_.sortBy(roleState.users, (u) => u.userName ?? '').map((u) => {
        return (
          <ListItem
            key={u.id}
            secondaryAction={
              <Tooltip title="Убрать пользователя">
                <IconButton
                  disabled={u.status === 'saving'}
                  onClick={() => handleRemoveUser(u.id)}
                >
                  <Clear/>
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemAvatar>
              <PersonIcon/>
            </ListItemAvatar>
            <ListItemText primary={u.userName ?? ''}/>
          </ListItem>
        )
      })}
    </List>
  )
}

export default RoleUsersList
