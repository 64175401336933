import Icon from '@mui/material/Icon';
import filter from '../assets/filter.svg';
import filterDisabled from '../assets/filterDisabled.svg';
import { FunctionComponent } from 'react';

interface FilterIconProps {
  enabled: boolean;
}

export const FilterIcon: FunctionComponent<FilterIconProps> = (props) => (
  <Icon
    sx={{
      paddingLeft: 0.4,
      paddingRight: 0.4,
      height: '1.5rem',
    }}
  >
    {props.enabled && <img src={filter} alt="" />}
    {!props.enabled && <img src={filterDisabled} alt="" />}
  </Icon>
);
