import { handleErrors } from '../errorHandler'
import { API_ADDRESS, apiAuthFetch } from '../apiFetch'

const RIGHTS_API_ADDRESS = `${API_ADDRESS}/api/administration/certificates`

export class CertificatesApi {
  async getCertificates() {
    return apiAuthFetch()
      .get(RIGHTS_API_ADDRESS)
      .then(handleErrors)
      .then<string[]>((res) => res.json())
  }

}

const certificatesApi = new CertificatesApi()

export default certificatesApi
