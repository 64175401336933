import React from "react";
import _ from "lodash";
import { Fragment, FunctionComponent } from "react";
import { Skeleton, TableCell, TableRow } from "@mui/material";

interface Props {
  rows: number;
  columns: number;
}

const LoadingTableBody: FunctionComponent<Props> = (props) => {
  return (
    <Fragment>
      {_.range(props.rows).map((r, rowIndex) => (
        <TableRow key={rowIndex}>
          {_.range(props.columns).map((c, columnIndex) => (
            <TableCell key={columnIndex}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Fragment>
  );
};

export default LoadingTableBody;
