import { MeDto } from './types/MeDto'
import { apiAuthFetch, API_ADDRESS } from '../apiFetch'
import { handleErrors } from '../errorHandler'

const USER_API_ADDRESS = `${API_ADDRESS}/api/me`

export class MeApi {
  async getUser() {

    return apiAuthFetch()
      .get(USER_API_ADDRESS)
      .then(handleErrors)
      .then<MeDto>((res) => res.json())

  }
}

const userApi = new MeApi()

export default userApi
