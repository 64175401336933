import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Grid, Box,
} from '@mui/material'
import { useAppDispatch } from '../../../../app/hooks'
import { hideCreateUserDialog } from '../../usersListSlice'
import { object, string, TypeOf, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider, SubmitErrorHandler } from 'react-hook-form'
import FormInput from '../../../../components/Form/FormInput'
import { createUser, loadUsers } from '../../userAction'
import { unwrapResult } from '@reduxjs/toolkit'

interface Props {
  open: boolean;
}

const commonSx = { mb: 1 }

const registerSchema = object(
  {
    userName: string()
      .min(5, 'Имя пользователя не должно быть менее 5 символов')
      .max(32, 'Имя пользователя не должно быть более 32 символов'),
    email: string()
      .nonempty('Требуется почта')
      .email('Неверный формат почты')
      .max(256, 'Почта не должна быть более 256 символов'),
    password: string()
      .min(8, 'Пароль не должен быть менее 8 символов')
      .max(32, 'Пароль не должен быть более 32 символов')
      .regex(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, 'Пароль должен содержать хотя бы 1 специальный символ')
      .regex(/[A-Z]/, 'Пароль должен содержать хотя бы 1 символ в верхнем регистре')
      .regex(/[0-9]/, 'Пароль должен содержать хотя бы 1 цифру')
      .refine(s => !/[А-Яа-я]/.test(s), 'Пароль не должен символов кириллицы'),
    fullName: string()
      .min(5, 'Полное имя пользователя не должно быть менее 5 символов')
      .max(100, 'Полное имя пользователя не должно быть более 100 символов'),
    position: string()
      .min(3, 'Должность не должна быть менее 3 символов')
      .max(32, 'Должность не должна быть более 32 символов'),
  })

type RegisterInput = TypeOf<typeof registerSchema>;

const CreateUserDialog: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()

  const [userName, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [position, setPosition] = useState('')

  const methods = useForm<RegisterInput>(
    {
      resolver: zodResolver(registerSchema),
    })

  const {
          reset,
          handleSubmit,
          formState: { isSubmitSuccessful },
        } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    dispatch(createUser(values))
      .then(unwrapResult)
      .then(() => {
        dispatch(loadUsers())
        dispatch(hideCreateUserDialog())
        reset({
                userName: '',
                email: '',
                password: '',
                fullName: '',
                position: '',
              })
      }).catch(() => {
      reset({
              userName: '',
              email: '',
              password: '',
              fullName: '',
              position: '',
            })
    })

  }

  const onErrorHandler: SubmitErrorHandler<RegisterInput> = (values) => {
    console.error(values)
  }

  const renderContent = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <FormInput
            name="userName"
            required
            fullWidth
            label="Имя пользователя"
            type="userName"
            defaultValue=""
            value={userName}
            onChange={(e) => setUsername(e.target.value)}
            sx={commonSx}
          />
          <FormInput
            name="password"
            required
            fullWidth
            label="Пароль"
            type="password"
            defaultValue=""
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={commonSx}
          />
          <FormInput
            name="email"
            required
            fullWidth
            label="Почта"
            type="email"
            defaultValue=""
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={commonSx}
          />
          <FormInput
            name="fullName"
            required
            fullWidth
            label="Полное имя"
            type="fullName"
            defaultValue=""
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            sx={commonSx}
          />
          <FormInput
            name="position"
            required
            fullWidth
            label="Должность"
            type="position"
            defaultValue=""
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            sx={commonSx}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Создать нового пользователя</DialogTitle>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <DialogContent>{renderContent()}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dispatch(hideCreateUserDialog())
                reset(
                  {
                    userName: '',
                    email: '',
                    password: '',
                    fullName: '',
                    position: '',
                  })
              }}
              color="primary">
              Отменить
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Создать
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default CreateUserDialog
