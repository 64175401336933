import { Fragment, FunctionComponent } from 'react'
import { Tooltip, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { UserListItem } from '../../selectRoleUsersSlice'
import { selectMe } from '../../../user/meSlice'
import IconButton from '@mui/material/IconButton'
import CreateIcon from '@mui/icons-material/Create'
import { showUpdateUserDialog, setUpdateUserDialog } from '../../usersListSlice'

interface Props {
  user: UserListItem;
}

const UpdateButton: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()

  const handleUpdate = () => {
    dispatch(
      setUpdateUserDialog(props.user),
    )
    dispatch(
      showUpdateUserDialog(),
    )
  }

  return (
    <Tooltip title={
      <Typography color={'white'} variant={'valueText'}>
        Обновить пользователя
      </Typography>
    }>
      <IconButton aria-label="update" onClick={handleUpdate}>
        <CreateIcon/>
      </IconButton>
    </Tooltip>
  )
}

export default UpdateButton
