import {
  type FC,
  type ReactNode,
} from 'react'
import { NavLink } from 'react-router-dom'

interface StyledNavLinkProps {
  to: string
  children: ReactNode
  onClick?: () => void
}

export const StyledNavLink: FC<StyledNavLinkProps> = (
  {
    to,
    children,
    onClick,
  }) => {
  return <NavLink
    onClick={onClick}
    style={{
      display: 'flex',
      flex: 'auto',
      color: 'white',
    }}
    to={to}
    end
  >
    {children}
  </NavLink>
}
