import { combineReducers } from '@reduxjs/toolkit'
import user from '../user/userSlice'
import me from '../user/meSlice'

export const currentUser = combineReducers(
  {
    user: combineReducers(
      {
        user: user,
        me: me,
      }),
  })
