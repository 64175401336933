import { TreeItem, treeItemClasses } from '@mui/lab';
import { Stack } from '@mui/material';
import { FunctionComponent, memo, useEffect } from 'react';
import { BaseNode, LeafNode } from '../Tree';
import ProjectTreeItem from './ProjectTreeItem';
import { useAppDispatch } from '../../../app/hooks';
import { addExpanded } from '../projectsSlice';
import { styled } from '@mui/material/styles';

export const BaseLeafPrefix = 'BaseLeaf.';

interface BaseLeafTreeItemProps {
  node: BaseNode;
  key: string;
}

export const Title: FunctionComponent<{ title: string }> = (props) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
    >
      {props.title}
    </Stack>
  );
};

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.label}`]: {
    marginTop: 8,
    marginBottom: 8,
  },
  [`& .${treeItemClasses.selected}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${treeItemClasses.focused}, & .${treeItemClasses.selected}, & .${treeItemClasses.selected}.${treeItemClasses.focused}`]:
    {
      backgroundColor: `transparent`,
    },

  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: 'bold',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `transparent`,
      color: theme.palette.primary.main,
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
}));

const BaseLeafTreeItem: FunctionComponent<BaseLeafTreeItemProps> = (props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(addExpanded([BaseLeafPrefix + props.node.id]));
  }, []);

  return (
    <StyledTreeItem
      key={BaseLeafPrefix + props.node.id}
      nodeId={BaseLeafPrefix + props.node.id}
      label={<Title title={props.node.value} />}
    >
      {props.node.children.map((child) => {
        if (child instanceof LeafNode) {
          return (
            <ProjectTreeItem
              key={child.value.data.fileId}
              projectId={child.value.data.fileId}
            />
          );
        } else {
          return <BaseLeafTreeItem key={child.id} node={child} />;
        }
      })}
    </StyledTreeItem>
  );
};

export default memo(BaseLeafTreeItem);
