import { createAsyncThunk } from '@reduxjs/toolkit'
import rightsApi from '../../infrastructure/api/administration/rightsApi'
import { RestrictionDialogType } from './rightsSlice'

export const loadRights = createAsyncThunk('rights-list/load', async () => {
  return await rightsApi.getRights()
})

export const updateRight = createAsyncThunk('rights-list/update', async (data: {
  rightId: number,
  configuringParameter: string,
  description: string
}) => {
  return await rightsApi.updateRight(data.rightId, data.configuringParameter, data.description)
})

export const createRight = createAsyncThunk('rights-list/create', async (data: {
  configuringParameter: string,
  description: string,
  type: RestrictionDialogType
}) => {
  return await rightsApi.createRight(data.configuringParameter, data.description, data.type)
})

export const deleteRight = createAsyncThunk('rights-list/delete', async (data: {
  rightId: number
}) => {
  return await rightsApi.deleteRight(data.rightId)
})


