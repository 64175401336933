import { FC, useCallback, useState } from 'react'
import { DrawerHeaderStyled } from './DrawerHeaderStyles'
import IconButton from '@mui/material/IconButton'
import {
  Drawer,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Clear } from '@mui/icons-material'
import ProjectsTreeView from '../components/ProjectsTreeView'
import { Tree as ProjectTree } from '../Tree'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectProjects, selectTree } from '../projectsSlice'
import {
  loadProject as loadProjects,
  loadProjectsFilteredContent,
} from '../actions'
import React from 'react'
import { DrawerDraggerStyled } from './DrawerDraggerStyled'
import { FilterIcon } from '../../../components/FilterIcon'
import { setDrawerWidth } from '../../../app/storageHelpers'

interface Props {
  tree: ProjectTree;
  open: boolean;
  drawerWidth: number;

  setDrawerWidth(width: number): void;
}

export const TreeViewDrawer: FC<Props> = (props: Props) => {
  const treeState = useAppSelector(selectTree)
  const dispatch = useAppDispatch()
  const projects = useAppSelector(selectProjects)
  const [searchFilter, setSearchFilter] = useState<string | undefined>(
    undefined,
  )
  const handleSearch = async () => {
    await dispatch(loadProjects())

    const ids = projects.map((p) => p.data.fileId)
    if (searchFilter) {
      await dispatch(loadProjectsFilteredContent({ ids, searchFilter }))
    }
  }

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchFilter(event.target.value)

    if (event.target.value.length === 0) {
      dispatch(loadProjects())
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleClickClear = () => {
    setSearchFilter(undefined)
    dispatch(loadProjects())
  }

  const handleMouseDown = (e: any) => {
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback((e: any) => {
    const newWidth = e.clientX - document.body.offsetLeft
    if (newWidth > 200 && newWidth < (window.screen.width / 7) * 4) {
      props.setDrawerWidth(newWidth)
      setDrawerWidth(newWidth)

      if (window) {
        if (window.getSelection) {
          if (window.getSelection()?.empty) {
            // Chrome
            window.getSelection()?.empty()
          } else if (window.getSelection()?.removeAllRanges) {
            // Firefox
            window.getSelection()?.removeAllRanges()
          }
        } else if (document.getSelection()) {
          // IE?
          document.getSelection()?.empty()
        }
      }
    }
  }, [])

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: props.drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: props.drawerWidth,
            boxSizing: 'border-box',
          },
          overflowX: 'hidden',
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
      <DrawerDraggerStyled onMouseDown={(e) => handleMouseDown(e)}/>
      <Stack direction="column" spacing={1} sx={{ ml: '5px', mr: 1 }}>
        <DrawerHeaderStyled>
          <TextField
            onKeyPress={handleKeyPress}
            onChange={handleSearchTextChange}
            value={searchFilter ?? ''}
            sx={{ width: '100%', padding: 1, marginTop: 10 }}
            InputProps={{
              //startAdornment: <SearchIcon color="action" />,
              endAdornment: (
                <InputAdornment position="end">
                  {(searchFilter?.length ?? 0) > 0 && (
                    <Tooltip title="Clear">
                      <IconButton onClick={handleClickClear}>
                        <Clear/>
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Поиск">
                    <span>
                      <IconButton
                        color="primary"
                        disabled={(searchFilter?.length ?? 0) <= 0}
                        onClick={() => handleSearch()}
                      >
                        <FilterIcon
                          enabled={!((searchFilter?.length ?? 0) <= 0)}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DrawerHeaderStyled>


        <ProjectsTreeView
          children={props.tree.root.children}
          expandAll={treeState.expandAll}
        />
      </Stack>
    </Drawer>
  )
}
