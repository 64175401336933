import { FunctionComponent, Fragment } from 'react'
import {
  Container,
  Box,
  List,
  ListItemText,
  TableBody,
  Table,
  ListSubheader, ListItem, ListItemSecondaryAction, Tooltip, Typography, Stack,
} from '@mui/material'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import LoadingTableBody from '../LoadingTableBody'
import {
  selectRights, setEditRight, showEditRightsDialog, setAddRightDialogType, RestrictionDialogType,
} from '../../rightsSlice'
import IconButton from '@mui/material/IconButton'
import CreateIcon from '@mui/icons-material/Create'
import { loadRights, deleteRight } from '../../rightsActions'
import DeleteIcon from '@mui/icons-material/Delete'
import { StyledNavLink } from '../../../../components/StyledNavLink'
import { setBackUrl } from '../../roleSlice'
import GroupIcon from '@mui/icons-material/Group'

const RightsList: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const rightsListState = useAppSelector(selectRights)

  const orderedItems = _.orderBy(rightsListState.rights, [
    'grouping',
    'description',
  ])

  const groups = _.groupBy(orderedItems, 'grouping')

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        {rightsListState.status === 'loading' && (
          <Table>
            <TableBody>
              <LoadingTableBody rows={5} columns={2}/>
            </TableBody>
          </Table>
        )}
        {rightsListState.status === 'idle' && (
          <List>
            {_.map(groups, (group, name) => (
              <Fragment key={name}>
                <ListSubheader color="primary" disableSticky key={name}>
                  <Typography variant={'h5'}>
                    {name}
                  </Typography>
                </ListSubheader>
                {group.map((r) => {
                  return (
                    <ListItem key={r.rightId}>
                      <ListItemText
                        primary={
                          <Stack direction={'column'}>
                            <Typography variant={'valueName'}>
                              {r.description}
                            </Typography>
                            {r.name?.startsWith('Restrictions') &&
                              <Typography variant={'valueName'}>
                                {r.configuringParameter}
                              </Typography>
                            }
                          </Stack>
                        }
                        secondary={
                          <Stack spacing={1} direction={'column'}>
                            <Typography variant={'description'}>
                              {r.name}
                            </Typography>
                          </Stack>}
                      />
                      <ListItemSecondaryAction>
                        {!(
                            r.name.startsWith('Permissions') ||
                            r.name.startsWith('Restrictions.Certificates')
                          )
                          &&
                          <Stack direction={'row'} alignItems={'center'} spacing={2}>
                            {r.roles && r.roles.length > 0 && <Tooltip
                              title={
                                <>
                                  {_.sortBy(r.roles, (u) => u.name)?.map((r) => (
                                    <StyledNavLink
                                      key={r.id}
                                      onClick={() => {
                                        dispatch(setBackUrl('/administration/rights'))
                                      }}
                                      to={`/administration/roles/${r.id}`}
                                    >
                                      <Typography color={'white'} variant={'valueText'}>
                                        {r.name}
                                      </Typography>
                                    </StyledNavLink>
                                  ))}
                                </>
                              }
                            >
                              <GroupIcon/>
                            </Tooltip>}
                            <Tooltip title={

                              <Typography color={'white'} variant={'valueText'}>
                                Редактировать разрешение
                              </Typography>

                            }>
                              <IconButton
                                aria-label="update"
                                onClick={() => {
                                  dispatch(setEditRight(r))
                                  if (r.name.startsWith('Restrictions.Groups')) {
                                    dispatch(setAddRightDialogType(RestrictionDialogType.group))
                                  }
                                  if (r.name.startsWith('Restrictions.Projects')) {
                                    dispatch(setAddRightDialogType(RestrictionDialogType.project))
                                  }
                                  if (r.name.startsWith('Restrictions.NamedCertificate')) {
                                    dispatch(setAddRightDialogType(RestrictionDialogType.certificate))
                                  }
                                  dispatch(showEditRightsDialog())
                                }}
                                disabled={rightsListState.status === 'saving'}
                              >
                                <CreateIcon/>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={
                              <Typography color={'white'} variant={'valueText'}>
                                Удалить право
                              </Typography>
                            }>
                              <IconButton
                                disabled={r.roles && r.roles?.length > 0}
                                aria-label="delete"
                                onClick={() => {
                                  dispatch(deleteRight({ rightId: r.rightId }))
                                    .then(() => dispatch(loadRights()))
                                }}>
                                <DeleteIcon/>
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        }
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </Fragment>
            ))}
          </List>
        )}
      </Box>
    </Container>
  )
}

export default RightsList
