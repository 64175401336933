import { TreeItem } from '@mui/lab';
import { FunctionComponent, memo, useEffect } from 'react';
import DocumentTreeItem from './DocumentTreeItem';
import { Document, addExpanded } from '../projectsSlice';
import { useAppDispatch } from '../../../app/hooks';

const ProjectGroupTreeItem: FunctionComponent<{
  projectId: number;
  group: string;
  documents: Document[];
}> = (props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(addExpanded(['group' + props.projectId + props.group]));
  }, []);

  return (
    <TreeItem
      nodeId={'group' + props.projectId + props.group}
      label={props.group}
    >
      {props.documents.map((document) => (
        <DocumentTreeItem key={document.data.fileId} document={document} />
      ))}
    </TreeItem>
  );
};

export default memo(ProjectGroupTreeItem);
