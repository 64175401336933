import jwtDecode, { JwtPayload } from 'jwt-decode'
import { LoginResponse } from '../infrastructure/api/auth/contracts'
import authApi from '../infrastructure/api/auth/authApi'

export const isTokenValid = (token: string): boolean | undefined => {
  if (token) {
    const decodedToken: JwtPayload = jwtDecode(token)
    const { exp } = decodedToken
    if (exp) {
      if (exp * 1000 < new Date().getTime()) {
        return false
      }
      return true
    }
  } else {
    return undefined
  }
}

export const getRefreshToken = (): string => {
  const token = localStorage.getItem('os_refresh_token') ?? ''
  return token
}

export const getAccessToken = (): string => {
  const token = localStorage.getItem('os_access_token') ?? ''
  return token
}

export const getDrawerWidth = (): number => {
  const width = localStorage.getItem('os_drawer_width')
  if (width) {
    return Number.parseInt(width)
  }
  return 500
}

export const setDrawerWidth = (width: number): void => {
  localStorage.setItem('os_drawer_width', width.toString())
}

export const getDrawerState = (): boolean => {
  const isOpened = localStorage.getItem('os_drawer_state') ?? 'false'
  return /true/.test(isOpened)
}

export const setDrawerState = (state: boolean): void => {
  localStorage.setItem('os_drawer_state', state.toString())
}

export const getValidToken = async () => {
  let token = getAccessToken()
  const isValid = isTokenValid(token)

  if (!isValid) {
    const refreshToken = getRefreshToken()

    const result = await authApi.refresh({ token, refreshToken })

    if (result.id && result.userName && result.token && result.refreshToken) {
      renewCredits(result)
      token = getAccessToken()
    }
  }

  return token
}

export const clearCredits = (): void => {
  localStorage.setItem('os_access_token', '')
  localStorage.setItem('os_refresh_token', '')
  localStorage.setItem('os_user_name', '')
  localStorage.setItem('os_user_id', '')
}

export const renewCredits = (response: LoginResponse): string => {
  if (response.token && response.refreshToken) {
    localStorage.setItem('os_access_token', response.token)
    localStorage.setItem('os_refresh_token', response.refreshToken)
    localStorage.setItem('os_user_name', response.userName)
    localStorage.setItem('os_user_id', response.id)
  }
  return response.token
}

export const saveCredits = (userName: string): void => {
  localStorage.setItem('os_user_name', userName)
}

export const getLocalStorageUser = (): User | undefined => {
  const token = localStorage.getItem('os_access_token') ?? ''
  const name = localStorage.getItem('os_user_name')
  const id = localStorage.getItem('os_user_id')

  if (token && name && id) {
    return { id: id, userName: name } as User
  }

  return undefined
}

type User = { id: string, userName: string };
