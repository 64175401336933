import { TreeItem } from '@mui/lab';
import { FunctionComponent, memo } from 'react';
import { Document } from '../projectsSlice';

export const DocumentPrefix = 'document.';

const DocumentTreeItem: FunctionComponent<{ document: Document }> = (props) => {
  return (
    <TreeItem
      nodeId={`${DocumentPrefix}${
        props.document.projectId
      }.${props.document.data.fileId.toString()}`}
      label={props.document.data.fileName ?? '-'}
    />
  );
};

export default memo(DocumentTreeItem);
