import {
  Tooltip,
  CircularProgress,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import AuthenticatedDownloadLink from './AuthenticatedDownloadLink';
import { API_ADDRESS } from '../infrastructure/api/apiFetch';

interface Props {
  projectId: number;
  documentId: number;
  revision: string;
  fileName: string;
  certLabel: string;
  dataLabel: string;
  onError?: (error: Error) => void;
}

const DownloadFile: FunctionComponent<Props> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloading = () => {
    setIsDownloading(true);
  };

  const handleDownloaded = () => {
    setIsDownloading(false);
  };

  const handleDownloadError = (error: Error) => {
    setIsDownloading(false);
    props.onError && props.onError(error);
  };

  return (
    <AuthenticatedDownloadLink
      method="GET"
      url={`${API_ADDRESS}/api/projects/${props.projectId}/items/${props.documentId}/revisions/${props.revision}/stream`}
      filename={props.fileName}
      isDownloading={isDownloading}
      onDownloading={handleDownloading}
      onDownloaded={handleDownloaded}
      onError={handleDownloadError}
    >
      {isDownloading ? (
        <CircularProgress size={20} sx={{ m: 1 }} />
      ) : (
        <Tooltip title="Скачать файл">
          <ListItemButton
            role={undefined}
            sx={{
              width: 350,
            }}
            dense
          >
            <ListItemText
              id={`${props.certLabel} ${props.dataLabel}`}
              primary={`${props.certLabel}`}
              primaryTypographyProps={{ style: { whiteSpace: 'break-spaces' } }}
              secondary={`${props.dataLabel}`}
            />
          </ListItemButton>
        </Tooltip>
      )}
    </AuthenticatedDownloadLink>
  );
};

export default DownloadFile;
