import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Grid, Box,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { hideUpdateUserDialog, selectEditUser, setUpdateUserDialog } from '../../usersListSlice'
import { object, string, infer as Infer, optional } from 'zod'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { useForm, SubmitHandler, FormProvider, SubmitErrorHandler } from 'react-hook-form'
import FormInput from '../../../../components/Form/FormInput'
import { loadUsers, updateUser } from '../../userAction'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectMe } from '../../../user/meSlice'

interface Props {
  open: boolean;
}

const commonSx = { mb: 1 }

const updateSchema = object(
  {
    userName: string()
      .min(5, 'Имя пользователя не должно быть менее 5 символов')
      .max(32, 'Имя пользователя не должно быть более 32 символов'),
    email: string()
      .nonempty('Требуется почта')
      .email('Неверный формат почты')
      .max(256, 'Почта не должна быть более 256 символов'),
    password: string()
      .min(8, 'Пароль не должен быть менее 8 символов')
      .max(32, 'Пароль не должен быть более 32 символов')
      .regex(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, 'Пароль должен содержать хотя бы 1 специальный символ')
      .regex(/[A-Z]/, 'Пароль должен содержать хотя бы 1 символ в верхнем регистре')
      .regex(/[0-9]/, 'Пароль должен содержать хотя бы 1 цифру')
      .refine(s => !/[А-Яа-я]/.test(s), 'Пароль не должен символов кириллицы')
      .optional()
      .or(z.literal('')),
    fullName: string()
      .min(5, 'Полное имя пользователя не должно быть менее 5 символов')
      .max(100, 'Полное имя пользователя не должно быть более 100 символов'),
    position: string()
      .min(3, 'Должность не должна быть менее 3 символов')
      .max(32, 'Должность не должна быть более 32 символов'),
  })

type UpdateInput = Infer<typeof updateSchema>;

const UpdateUserDialog: FunctionComponent<Props> = ({ open }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectEditUser)
  const me = useAppSelector(selectMe)

  const methods = useForm<UpdateInput>(
    {
      resolver: zodResolver(updateSchema),
      mode: 'onChange',
    })

  const {
          control,
          reset,
          handleSubmit,
          formState: { isSubmitSuccessful },
        } = methods

  useEffect(() => {
    reset(
      {
        userName: user?.userName,
        email: user?.email,
        password: '',
        fullName: user?.fullName,
        position: user?.position,
      })
  }, [user])

  const onSubmitHandler: SubmitHandler<UpdateInput> = (values) => {
    if (user && me) {
      dispatch(updateUser(
        {
          id: user.id,
          userId: me.me?.user.id ?? '',
          userName: values.userName,
          fullName: values.fullName,
          email: values.email,
          position: values.position,
          password: values.password,
        }))
        .then(unwrapResult)
        .then(() => {
          dispatch(loadUsers())
          dispatch(hideUpdateUserDialog())
        }).then(() => {
      }).catch(() => {
        reset(
          {
            userName: user?.userName,
            email: user?.email,
            password: '',
            fullName: user?.fullName,
            position: user?.position,
          })
      })
    }
  }

  const onErrorHandler: SubmitErrorHandler<UpdateInput> = (values) => {
    console.error(values)
  }

  const renderContent = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <FormInput
            name="userName"
            required
            fullWidth
            label="Имя пользователя"
            defaultValue={user?.userName}
            type="userName"
            sx={commonSx}
          />
          <FormInput
            name="email"
            required
            fullWidth
            label="Почта"
            defaultValue={user?.email}
            type="email"
            sx={commonSx}
          />
          <FormInput
            name="fullName"
            required
            fullWidth
            label="Полное имя"
            defaultValue={user?.fullName}
            type="fullName"
            sx={commonSx}
          />
          <FormInput
            name="position"
            required
            fullWidth
            label="Должность"
            defaultValue={user?.position}
            type="position"
            sx={commonSx}
          />
          <FormInput
            name="password"
            fullWidth
            label="Пароль"
            defaultValue={''}
            type="password"
            sx={commonSx}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(hideUpdateUserDialog())
        reset({
                userName: user?.userName,
                email: user?.email,
                password: '',
                fullName: user?.fullName,
                position: user?.position,
              })
      }}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Обновить данные пользователя</DialogTitle>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <DialogContent>{renderContent()}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dispatch(hideUpdateUserDialog())
                dispatch(
                  setUpdateUserDialog(undefined),
                )
                reset()
              }}
              color="primary">
              Отменить
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Обновить
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default UpdateUserDialog
