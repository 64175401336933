import { API_ADDRESS, apiAuthFetch } from '../apiFetch'
import {
  DocumentCertifcatesResponse,
  DocumentVariablesResponse,
  ProjectContentResponse,
  ProjectsResponse,
  ProjectVariablesResponse,
} from './contracts'
import { handleErrors } from '../errorHandler'

class ProjectsApi {
  getProjects = () => {
    return apiAuthFetch()
      .get(`${API_ADDRESS}/api/projects`)
      .then(handleErrors)
      .then<ProjectsResponse>((res) => res.json())
  }

  getProjectContent = (projectId: number) => {
    return apiAuthFetch()
      .get(`${API_ADDRESS}/api/projects/${projectId}/items`)
      .then(handleErrors)
      .then<ProjectContentResponse>((res) => res.json())
  }

  searchContent = (ids: number[], searchFilter: string) => {
    return apiAuthFetch()
      .post(`${API_ADDRESS}/api/projects/search`, { ids, searchFilter })
      .then(handleErrors)
      .then<ProjectContentResponse>((res) => res.json())
  }

  getProjectVariables = (projectId: number) => {
    return apiAuthFetch()
      .get(`${API_ADDRESS}/api/projects/${projectId}/variables`)
      .then(handleErrors)
      .then<ProjectVariablesResponse>((res) => res.json())
  }

  getDocumentVariables = (
    projectId: number,
    documentId: number,
    ext?: string,
  ) => {
    let url = new URL(
      `${API_ADDRESS}/api/projects/${projectId}/items/${documentId}/variables`,
    )

    let urlStr = url.toString()

    if (ext) {
      let params = new URLSearchParams(url.search)
      params.append('ext', ext)
      urlStr = `${urlStr}?${params}`
    }

    return apiAuthFetch()
      .get(urlStr)
      .then(handleErrors)
      .then<DocumentVariablesResponse>((res) => res.json())

  }

  getDocumentCertificates = (projectId: number, documentId: number) => {
    let url = new URL(
      `${API_ADDRESS}/api/projects/${projectId}/items/${documentId}/certificates`,
    )
    return apiAuthFetch()
      .get(url.toString())
      .then(handleErrors)
      .then<DocumentCertifcatesResponse>((res) => res.json())
  }
}

const projectsApi = new ProjectsApi()

export default projectsApi
