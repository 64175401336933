import { handleErrors } from '../errorHandler'
import { apiAuthFetch, API_ADDRESS } from '../apiFetch'
import { UserDto } from './contracts/UserDto'
import { CreateUserRequest, UpdateUserRequest } from '../auth/contracts'

const USER_API_ADDRESS = `${API_ADDRESS}/api/administration/users`

export class UsersApi {
  async getUsers() {
    return apiAuthFetch()

      .get(USER_API_ADDRESS)
      .then(handleErrors)
      .then<UserDto[]>((res) => res.json())
  }

  async updateEnableLogin(userId: string, enableLogin: boolean) {
    return apiAuthFetch()
      .put(`${USER_API_ADDRESS}/${userId}/enable-login`, { enableLogin })
      .then(handleErrors)
      .then(() => Promise.resolve())
  }

  createUser = (createUserRequest: CreateUserRequest) => {
    return apiAuthFetch()
      .post(`${USER_API_ADDRESS}`, createUserRequest)
      .then(handleErrors)
      .then(() => Promise.resolve())
  }

  updateUser = (userId: string, updateUserRequest: UpdateUserRequest) => {
    return apiAuthFetch()
      .put(`${USER_API_ADDRESS}/${userId}`, updateUserRequest)
      .then(handleErrors)
      .then(() => Promise.resolve())
  }

  deleteUser = (userId: string) => {
    return apiAuthFetch()
      .delete(`${USER_API_ADDRESS}/${userId}`)
      .then(handleErrors)
      .then(() => Promise.resolve())
  }
}

const usersApi = new UsersApi()

export default usersApi
