import { FunctionComponent, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Box, Grid, Typography, Button, Stack } from '@mui/material'
import UsersList from '../components/users/UsersList'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { checkPermission } from '../../user/meSlice'
import { Permissions_Administration_Edit } from '../../../infrastructure/types/permissions'
import { showCreateUserDialog, setActionError, selectUsersList, setError } from '../usersListSlice'
import { loadUsers } from '../userAction'
import ErrorDialog from '../../../components/ErrorDialog'
import CreateUserDialog from '../components/users/CreateUserDialog'
import UpdateUserDialog from '../components/users/UpdateUserDialog'
import ErrorBox from '../../../components/ErrorBox'
import { loadMe } from '../../user/meActions'

const UsersListPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const usersListState = useAppSelector(selectUsersList)

  useEffect(() => {
    dispatch(loadUsers())
  }, [])

  const canAdmin = useAppSelector(
    (root: RootState) =>
      checkPermission(root, Permissions_Administration_Edit),
  )

  const handleAddUser = () => {
    dispatch(showCreateUserDialog())
  }

  return (
    <Layout open={false} setOpen={
      () => {
      }
    } showDrawerButton={false}>
      <Box sx={{ width: '75%', display: 'flex' }} mt={10} mr={'auto'} ml={'auto'}>
        <Stack sx={{ width: '100%' }} direction="column">
          {usersListState.status === 'idle' && <Grid container direction="row" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Пользователи</Typography>
            </Grid>
            <Grid item>
              {canAdmin && (
                <Button variant="outlined" onClick={handleAddUser}>
                  Добавить пользователя
                </Button>
              )}
            </Grid>
          </Grid>}
          <UsersList/>
        </Stack>
      </Box>
      <CreateUserDialog
        open={usersListState.createUserDialogOpened}
      />
      <UpdateUserDialog
        open={usersListState.updateUserDialogOpened}
      />
      <ErrorDialog
        open={usersListState.actionError !== undefined}
        title={usersListState.actionError?.name || 'Error'}
        text={usersListState.actionError?.message ?? ''}
        onOk={() => dispatch(setActionError())}
      />
      {usersListState.error && (
        <ErrorBox
          error={usersListState.error}
        >
          <Button
            sx={{ maxWidth: '75%' }}
            variant="contained"
            onClick={() => {
              dispatch(loadUsers())
              dispatch(setError(undefined))
            }}>
            Попробовать снова
          </Button>
        </ErrorBox>
      )}
    </Layout>
  )
}

export default UsersListPage
