import React, { FunctionComponent } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'

interface ErrorBoxProps {
  error?: SerializedError;
  label?: string;
  children?: React.ReactNode;
}

const ErrorBox: FunctionComponent<ErrorBoxProps> = (props) => {
  return (
    <Box
      display="flex"
      minHeight="50vh"

      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        direction={'column'}
        spacing={2}
        maxWidth="75%"
        justifyContent="center"
        alignItems="center">
        <Typography variant="h2" color="error">
          {props.label || (props.error?.message ?? '')}
        </Typography>
        <Typography style={{ wordBreak: 'break-all' }}>
          Обратитесь в системному администратору, пожалуйста.
        </Typography>
        {props.children}
      </Stack>
    </Box>
  )
}

export default ErrorBox
