import { Fragment, FunctionComponent } from 'react'
import { Switch, Tooltip, Typography } from '@mui/material'
import { updateEnableLogin } from '../../userAction'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { UserListItem } from '../../selectRoleUsersSlice'
import { selectMe } from '../../../user/meSlice'

interface Props {
  user: UserListItem;
}

const EnableLoginSwitch: FunctionComponent<Props> = (props) => {
  const me = useAppSelector(selectMe)
  const dispatch = useAppDispatch()

  const handleChange = (value: boolean) => {
    dispatch(
      updateEnableLogin({ id: props.user.id, loginEnabled: value }),
    )
  }

  if (props.user.id === me.me?.user.id) {
    return <Fragment/>
  }

  return (
    <Tooltip title={
      props.user.loginEnabled ?
      <Typography color={'white'} variant={'valueText'}>
        Запретить вход
      </Typography> : <Typography color={'white'} variant={'valueText'}>
        Разрешить вход
      </Typography>}>
      <Switch
        checked={props.user.loginEnabled}
        disabled={props.user.status === 'saving'}
        onChange={(e) => handleChange(e.target.checked)}
      />
    </Tooltip>
  )
}

export default EnableLoginSwitch
