import { getAccessToken } from '../../app/storageHelpers'

export const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

export function apiFetch() {
  const request = (method: string) => {
    return function (
      url: string,
      body?: FormData | Object,
      headers?: HeadersInit,
    ) {
      const requestOptions = {
        method,
        headers,
      } as RequestInit;

      if (body) {
        if (body instanceof FormData) {
          requestOptions.body = body;
        } else {
          const newHeaders = new Headers(requestOptions.headers);
          newHeaders.set('Content-Type', 'application/json');
          newHeaders.set('Access-Control-Allow-Origin', '*');

          requestOptions.headers = newHeaders;
          requestOptions.body = JSON.stringify(body);
        }
      }

      return fetch(url, requestOptions);
    };
  };

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
  };
}

export function apiAuthFetch() {
  const authHeader = (accessToken: string, init?: HeadersInit) => {
    const headers = new Headers(init);

    const bearer = `Bearer ${accessToken}`;
    headers.append('Authorization', bearer);

    return headers;
  };

  const request = (method: string) => {
    return async function (
      url: string,
      body?: FormData | Object,
      headers?: HeadersInit,
    ) {
      const token = getAccessToken();

      const requestOptions = {
        method,
        headers: authHeader(token, headers),
      } as RequestInit;

      if (body) {
        if (body instanceof FormData) {
          requestOptions.body = body;
        } else {
          const newHeaders = new Headers(requestOptions.headers);
          newHeaders.set('Content-Type', 'application/json');

          requestOptions.headers = newHeaders;
          requestOptions.body = JSON.stringify(body);
//requestOptions.credentials= 'include'
        }
      }

      return fetch(url, requestOptions);
    };
  };

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
  };
}
