import { createAsyncThunk } from '@reduxjs/toolkit'
import authApi from '../../infrastructure/api/auth/authApi'
import { LoginRequest } from '../../infrastructure/api/auth/contracts'

export const login = createAsyncThunk(
  'login',
  async (request: LoginRequest) => {
    return authApi.login(request)
  },
)

export const logout = createAsyncThunk('revoke', async (token: string) => {
  return authApi.logout(token)
})
