export function isEmptyOrSpaces(str: string | undefined | null) {
  return !str || str.match(/^ *$/) !== null
}

export function replaceInvalidPathChars(str: string | undefined | null, char: string = '_') {
  return !str || str.replace(/[/\\?%*:|"<>]/g, char)
}

export function padWithLeadingZeros(num: number, totalLength: number) {
  const rev = String(num).padStart(totalLength, '0')
  if (rev === '00') {
    return ''
  }
  return rev
}
