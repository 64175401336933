import { Divider, Grid, Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Fragment, FunctionComponent } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectVariables } from '../projectVariablesSlice';
const maxWidth = (window.screen.width / 7) * 4;
const ProjectVariables: FunctionComponent = () => {
  const activeProject = useAppSelector((state) => state.tree.activeProject);
  const variablesStatus = useAppSelector(
    (state) => state.variables.project.status,
  );
  const variables = useAppSelector(selectVariables);

  if (!activeProject) {
    return <Fragment />;
  }

  if (variablesStatus === 'loading') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {activeProject.data.projectName && (
          <Stack direction={'column'} spacing={2} sx={{ maxWidth: maxWidth }}>
            <Typography variant="header">
              {activeProject.data.location}
            </Typography>
            <Typography variant="subHeader">
              {activeProject.data.projectName}
            </Typography>
            <Typography variant="subHeader">
              {`${activeProject.data.fileName} - ${activeProject.data.statusName}`}
            </Typography>
          </Stack>
        )}
      </Grid>

      <Grid item xs={12}>
        {variables.length !== 0 && (
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        )}
        {variables.map((v) => (
          <Stack
            sx={{ marginTop: 3.5, maxWidth: maxWidth }}
            direction={'column'}
            key={v.id}
            spacing={1}
          >
            <Typography variant="valueName">{v.name}</Typography>
            <Typography variant="valueText">{v.value}</Typography>
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
};

export default ProjectVariables;
