import { API_ADDRESS, apiAuthFetch } from '../apiFetch';
import { handleErrors } from '../errorHandler';

class SettingsApi {
  getConvertedExtensions = () => {
    return apiAuthFetch()
      .get(`${API_ADDRESS}/api/settings/converted-extensions`)
      .then(handleErrors)
      .then<string[]>((res) => res.json());
  };
}
const settingsApi = new SettingsApi();

export default settingsApi;
