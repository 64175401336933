import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { loadUsers, updateEnableLogin, createUser, deleteUser, updateUser } from './userAction'
import { RootState } from '../../app/store'
import { UserDto } from '../../infrastructure/api/administration/contracts/UserDto'
import { RoleDto } from '../../infrastructure/api/administration/contracts/RoleDto'

export interface UsersListState {
  status: 'idle' | 'loading' | 'failed';
  users: UserListItem[];
  editUser?: UserListItem
  createUserDialogOpened: boolean;
  updateUserDialogOpened: boolean;
  openConfirmDeleteDialog: boolean;
  error?: SerializedError;
  actionError?: SerializedError;
}

export interface UserListItem {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  id: string;
  userName?: string;
  email?: string;
  fullName?: string;
  position?: string;
  loginEnabled?: boolean;
  roles?: RoleDto[];
}

const initialState: UsersListState = {
  status: 'idle',
  createUserDialogOpened: false,
  updateUserDialogOpened: false,
  openConfirmDeleteDialog: false,
  users: [],
}

export const usersListSlice = createSlice(
  {
    name: 'users-list',
    initialState,
    reducers: {
      setActionError: (state, action: PayloadAction<Error | undefined>) => {
        state.actionError = action.payload
      },
      setError: (state, action: PayloadAction<Error | undefined>) => {
        state.error = action.payload
      },
      setUpdateUserDialog: (state, action: PayloadAction<UserListItem | undefined>) => {
        state.editUser = action.payload
      },
      showCreateUserDialog: (state) => {
        state.createUserDialogOpened = true
      },
      hideCreateUserDialog: (state) => {
        state.createUserDialogOpened = false
      },
      showUpdateUserDialog: (state) => {
        state.updateUserDialogOpened = true
      },
      hideUpdateUserDialog: (state) => {
        state.updateUserDialogOpened = false
      },
      setConfirmDeleteDialogOpen: (state, action: PayloadAction<boolean>) => {
        state.openConfirmDeleteDialog = action.payload
      },
    },
    extraReducers: (build) => {
      build.addCase(loadUsers.pending, (state, action) => {
        state.status = 'loading'
      })

      build.addCase(loadUsers.fulfilled, (state, action) => {
        state.status = 'idle'
        state.users = action.payload.map((u: UserDto) => {
          return {
            status: 'idle',
            ...u,
          } as UserListItem
        })
      })

      build.addCase(loadUsers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })

      build.addCase(updateEnableLogin.pending, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.id)
        if (user) {
          user.status = 'saving'
        }
      })

      build.addCase(updateEnableLogin.fulfilled, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.id)
        if (user) {
          user.status = 'idle'
          user.loginEnabled = action.meta.arg.loginEnabled
        }
      })

      build.addCase(updateEnableLogin.rejected, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.id)
        if (user) {
          user.status = 'failed'
          state.actionError = action.error
        }
      })

      build.addCase(createUser.pending, (state, action) => {
        state.users = [
          ...state.users,
          { status: 'loading' } as UserListItem,
        ]
      })

      build.addCase(createUser.fulfilled, (state, action) => {
        state.status = 'idle'
      })

      build.addCase(createUser.rejected, (state, action) => {
        state.status = 'failed'
        state.actionError = action.error
      })

      build.addCase(deleteUser.pending, (state, action) => {
        state.users = [
          ...state.users,
          { status: 'loading' } as UserListItem,
        ]
      })

      build.addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'idle'
      })

      build.addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed'
        state.actionError = action.error
      })

      build.addCase(updateUser.pending, (state, action) => {
        state.users = [
          ...state.users,
          { status: 'loading' } as UserListItem,
        ]
      })

      build.addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'idle'
      })

      build.addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed'
        state.actionError = action.error
      })
    },

  })

export const {
               setConfirmDeleteDialogOpen,
               setActionError,
               setError,
               setUpdateUserDialog,
               showCreateUserDialog,
               hideCreateUserDialog,
               showUpdateUserDialog,
               hideUpdateUserDialog,
             } = usersListSlice.actions
export const selectUsersList = (state: RootState) => state.administration.users.list
export const selectEditUser = (state: RootState) => state.administration.users.list.editUser

export default usersListSlice.reducer
