import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';

interface Props {
  text: string;
}

const ViewerNotification: FC<Props> = ({ text }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Box
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.grey[300],
            backgroundColor: (theme) => theme.palette.grey[500],
            minHeight: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" sx={{ color: 'white', textAlign: 'center' }}>
            {text}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ViewerNotification;
