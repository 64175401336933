import { UserManager, User } from 'oidc-client-ts'
import React, { PropsWithChildren, useRef, useEffect, FunctionComponent } from 'react'
import { setAuthHeader, setIdToken } from './auth-headers'

interface Props {
  manager: UserManager
}

const AuthProvider: FunctionComponent<PropsWithChildren<Props>> = (props) => {

  let userManager = useRef<UserManager>()
  useEffect(() => {
    userManager.current = props.manager
    const onUserLoaded = (user: User) => {
      setAuthHeader(user.access_token)
      setIdToken(user.id_token)
    }
    const onUserUnloaded = () => {
      setAuthHeader(null)
      setIdToken(null)
    }

    const onAccessTokenExpiring = () => {
      props.manager.startSilentRenew()
    }

    const onAccessTokenExpired = () => {
      props.manager.startSilentRenew()
    }

    const onUserSingedOut = () => {
      props.manager.stopSilentRenew()
    }

    userManager.current.events.addUserLoaded(onUserLoaded)
    userManager.current.events.addUserUnloaded(onUserUnloaded)
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring)
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired)
    userManager.current.events.addUserSignedOut(onUserSingedOut)

    return function cleanup() {
      if (userManager && userManager.current) {
        userManager.current.events.removeUserLoaded(onUserLoaded)
        userManager.current.events.removeUserUnloaded(onUserUnloaded)
        userManager.current.events.removeAccessTokenExpiring(onAccessTokenExpiring)
        userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired)
        userManager.current.events.removeUserSignedOut(onUserSingedOut)
      }
    }
  }, [props.manager])

  return (
    <>
      {React.Children.only(props.children)}
    </>
  )
}

export default AuthProvider

