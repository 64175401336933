import { TreeItem } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { FunctionComponent, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addExpanded, selectProjectById } from '../projectsSlice';
import LoadingTreeItem from './LoadingTreeItem';
import ProjectGroupTreeItem from './ProjectGroupTreeItem';
import _ from 'lodash';
import { CertifiedIcon } from '../../../components/CertifiedIcon';

export const ProjectPrefix = 'project.';

interface TitleProps {
  title: string;
  location: string;
  certificate?: boolean;
}
const Title: FunctionComponent<TitleProps> = (props) => {
  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="center" alignItems="center">
        {props.title}
        <Box flexGrow={1} />
        {props.certificate && (
          <Stack sx={{ paddingBottom: 1 }}>
            <CertifiedIcon />
          </Stack>
        )}
      </Stack>
      <Typography variant="description">{props.location}</Typography>
    </Stack>
  );
};

const ProjectTreeItem: FunctionComponent<{ projectId: number }> = (props) => {
  const project = useAppSelector((state) =>
    selectProjectById(state, props.projectId),
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(addExpanded([ProjectPrefix + props.projectId]));
  }, []);

  if (!project) {
    return <Typography>Project with ID {props.projectId} not found</Typography>;
  }

  const groups = _.groupBy(project.documents, (d) => d.data.group);

  return (
    <TreeItem
      key={project.data.fileId}
      nodeId={ProjectPrefix + props.projectId}
      label={
        <Title
          title={project.data.projectName || project.data.fileName}
          location={project.data.location}
          certificate={project.data.haveCertificate}
        />
      }
    >
      {!project.loading &&
        _.map(groups, (documents, group) => (
          <ProjectGroupTreeItem
            key={props.projectId + group}
            projectId={props.projectId}
            group={group}
            documents={documents}
          />
        ))}

      {project.loading && <LoadingTreeItem id={project.data.fileId} />}
    </TreeItem>
  );
};

export default memo(ProjectTreeItem);
