import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { loadProjectVariables } from "./actions";

const variablesAdapter = createEntityAdapter<Variable>({
  selectId: (variable) => variable.id,
});

interface ProjectVariablesState {
  status: "idle" | "loading";
  variables: EntityState<Variable>;
}

interface Variable {
  id: number;
  name: string;
  value: string;
}

const name = "project-variables";

const initialState: ProjectVariablesState = {
  status: "idle",
  variables: variablesAdapter.getInitialState(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(loadProjectVariables.pending, () => {
      return { ...initialState, status: "loading" };
    });

    build.addCase(loadProjectVariables.fulfilled, (state, action) => {
      const variables = action.payload.variables.map((v) => {
        return {
          id: v.variableId,
          name: v.variableName,
          value: v.valueText,
        } as Variable;
      });
      variablesAdapter.setMany(state.variables, variables);

      state.status = "idle";
    });
  },
});

export const { selectAll: selectVariables, selectById: selectVariableById } =
  variablesAdapter.getSelectors(
    (state: RootState) => state.variables.project.variables
  );

export default slice.reducer;
