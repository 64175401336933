import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TableBody,
  Table,
  Stack, Tooltip,
} from '@mui/material'
import _ from 'lodash'
import { Fragment, FunctionComponent } from 'react'
import EnableLoginSwitch from './EnableLoginSwitch'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectUsersList, UserListItem, setConfirmDeleteDialogOpen, setUpdateUserDialog } from '../../usersListSlice'
import LoadingTableBody from '../LoadingTableBody'
import DeleteButton from './DeleteButton'
import UpdateButton from './UpdateButton'
import GroupIcon from '@mui/icons-material/Group'
import { StyledNavLink } from '../../../../components/StyledNavLink'
import { setBackUrl, hideConfirmDeleteDialog } from '../../roleSlice'
import YesNoDialog from '../../../../components/YesNoDialog'
import { deleteRole } from '../../roleActions'
import { deleteUser, loadUsers } from '../../userAction'
import { unwrapResult } from '@reduxjs/toolkit'

const UsersList: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const usersListState = useAppSelector(selectUsersList)

  const handleDelete = (user: UserListItem) => {
    dispatch(
      deleteUser(user.id),
    ).then(unwrapResult)
     .then(() => {
       dispatch(loadUsers())
     })
  }

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        {usersListState.status === 'loading' && (
          <Table>
            <TableBody>
              <LoadingTableBody rows={5} columns={2}/>
            </TableBody>
          </Table>
        )}
        {usersListState.status === 'idle' && (
          <Fragment>
            <List>
              {_.sortBy(
                usersListState.users,
                (u) => u.userName ?? u.fullName,
              ).map((u) => {
                return (
                  <ListItem
                    key={u.id}
                    divider
                    secondaryAction={
                      u.status !== 'loading' &&
                      <Stack direction="row" spacing={3} alignItems={'center'}>
                        <UpdateButton user={u}/>
                        <DeleteButton user={u} handleClick={() => dispatch(setConfirmDeleteDialogOpen(true))}/>
                        <EnableLoginSwitch user={u}/>
                        {u.roles && <Tooltip
                          title={u.roles.length > 0
                                 ?
                                 <>
                                   {_.sortBy(u.roles, (u) => u.name)?.map((r) =>
                                                                            (
                                                                              <StyledNavLink
                                                                                key={r.id}
                                                                                onClick={() => {
                                                                                  dispatch(setBackUrl('/administration/users'))
                                                                                }}
                                                                                to={`/administration/roles/${r.id}`}
                                                                              >
                                                                                <Typography color={'white'}
                                                                                            variant={'valueText'}>
                                                                                  {r.name}
                                                                                </Typography>
                                                                              </StyledNavLink>
                                                                            ))}
                                 </> :
                                 <Typography color={'white'} variant={'valueText'}>
                                   Нет ролей
                                 </Typography>
                          }
                        >
                          <GroupIcon/>
                        </Tooltip>}
                      </Stack>
                    }
                  >
                    <ListItemText
                      primary={
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                          <Typography variant={'h6'}>
                            {u.userName}
                          </Typography>
                          <Typography variant={'valueText'}>
                            {u.email}
                          </Typography>
                        </Stack>}
                      secondary={
                        <Stack direction={'column'} spacing={1} mt={1}>
                          <Typography variant={'valueText'}>
                            {u.fullName}
                          </Typography>
                          <Typography variant={'valueText'}>
                            {u.position}
                          </Typography>
                        </Stack>
                      }
                      primaryTypographyProps={{
                        sx: { overflowWrap: 'anywhere' },
                      }}
                      secondaryTypographyProps={{
                        sx: { overflowWrap: 'anywhere' },
                      }}
                    ></ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Fragment>
        )}
      </Box>
      <YesNoDialog
        open={usersListState.openConfirmDeleteDialog}
        title="Удалить пользователя"
        onYes={() => {
          dispatch(setConfirmDeleteDialogOpen(false))
          if (usersListState.editUser) {
            handleDelete(usersListState.editUser)
          }
          dispatch(
            setUpdateUserDialog(undefined),
          )
        }}
        onNo={() => {
          dispatch(setConfirmDeleteDialogOpen(false))
          dispatch(
            setUpdateUserDialog(undefined),
          )
        }}
      >
        {`Вы действительно хотите удалить пользователя ${usersListState.editUser?.userName}`}
      </YesNoDialog>

    </Container>
  )
}

export default UsersList


