import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import usersApi from '../../infrastructure/api/administration/usersApi'
import { RootState } from '../../app/store'
import { loadUsers, updateEnableLogin } from './userAction'

export interface SelectRoleUserDialogState {
  status: 'idle' | 'loading' | 'failed';
  users: UserListItem[];
  selectUsersDialogOpened: boolean;
  dialogError?: SerializedError;
  actionError?: SerializedError;
}

export interface UserListItem {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  id: string;
  userName?: string;
  email?: string;
  fullName?: string;
  position?: string;
  loginEnabled?: boolean;
}

const initialState: SelectRoleUserDialogState = {
  status: 'idle',
  selectUsersDialogOpened: false,
  users: [],
}

export const selectRoleUsersSlice = createSlice(
  {
    name: 'select-role-users',
    initialState,
    reducers: {
      setActionError: (
        state, action: PayloadAction<Error | undefined>) => {
        state.actionError = action.payload
      },
    },
    extraReducers: (build) => {
      build.addCase(loadUsers.pending, (state, action) => {
        state.status = 'loading'
      })

      build.addCase(loadUsers.fulfilled, (state, action) => {
        state.status = 'idle'
        state.users = action.payload.map((u) => {
          return {
            status: 'idle',
            id: u.id,
            userName: u.userName,
            loginEnabled: u.loginEnabled,
          } as UserListItem
        })
      })

      build.addCase(loadUsers.rejected, (state, action) => {
        state.status = 'failed'
        state.dialogError = action.error
      })

      build.addCase(updateEnableLogin.pending, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.id)
        if (user) {
          user.status = 'saving'
        }
      })

      build.addCase(updateEnableLogin.fulfilled, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.id)
        if (user) {
          user.status = 'idle'
          user.loginEnabled = action.meta.arg.loginEnabled
        }
      })

      build.addCase(updateEnableLogin.rejected, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.id)
        if (user) {
          user.status = 'failed'
          state.actionError = action.error
        }
      })

      /*build.addCase(addUser.pending, (state, action) => {
        state.users = [
          ...state.users,
          { id: action.meta.arg, displayName: 'Adding...', status: 'loading' } as UserListItem,
        ]
      })

      build.addCase(addUser.fulfilled, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg)
        if (user) {
          user.status = 'idle'
          user.displayName = action.payload.displayName
          user.userName = action.payload.userName
          user.email = action.payload.email
          user.loginEnabled = action.payload.loginEnabled
        }
      })

      build.addCase(addUser.rejected, (state, action) => {
        state.users = state.users.filter((u) => u.id !== action.meta.arg)
        state.actionError = action.error
      })*/
    },
  })

export const { setActionError } = selectRoleUsersSlice.actions
export const selectRoleUsersDialog = (state: RootState) => state.administration.roles.edit.selectUsers

export default selectRoleUsersSlice.reducer
