import { Box, Button } from '@mui/material'
import { Fragment, FunctionComponent, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { loadProject as loadProjects } from './actions'
import ProjectVariables from './components/ProjectVariables'
import DocumentVariables from './components/DocumentVariables'
import {
  getDrawerState,
  getDrawerWidth,
} from '../../app/storageHelpers'
import { Tree as ProjectTree } from './Tree'
import { selectProjects, selectTree, setError } from './projectsSlice'
import DocumentPreview from './components/DocumentPreview'
import { loadConvertedExtensions } from '../settings/actions'
import ErrorBox from '../../components/ErrorBox'
import React from 'react'
import { DrawerHeaderStyled } from './styledComponents/DrawerHeaderStyles'
import { ProjectsPageContentStyled } from './styledComponents/ProjectsPageContentStyled'
import { TreeViewDrawer } from './styledComponents/TreeViewDrawer'
import Layout from '../../components/Layout/Layout'
import { selectUser } from '../user/userSlice'

const ProjectsPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)

  const storageDrawerWidth = getDrawerWidth()
  const storageDrawerState = getDrawerState()
  const [open, setOpen] = React.useState(storageDrawerState)
  const [drawerWidth, setStateDrawerWidth] = React.useState(storageDrawerWidth)

  const projects = useAppSelector(selectProjects)
  const treeState = useAppSelector(selectTree)

  const tree = useMemo(() => {
    let tree = new ProjectTree()
    if (projects.length > 0) {
      projects.forEach(function (project) {
        tree.addNode(
          project.data.fileId.toString(),
          project.data.path,
          project,
        )
      })
    }
    return tree
  }, [projects.length])

  useEffect(() => {
    if (user) {
      dispatch(loadProjects())
      dispatch(loadConvertedExtensions())
    }
  }, [dispatch, user])

  return (
    <Layout open={open && !treeState.error} showDrawerButton={!treeState.error} setOpen={setOpen}>
      <TreeViewDrawer
        tree={tree}
        open={open && !treeState.error}
        drawerWidth={drawerWidth}
        setDrawerWidth={setStateDrawerWidth}
      />
      <ProjectsPageContentStyled open={open} draweridth={drawerWidth}>
        {!treeState.error && (
          <Fragment>
            <DrawerHeaderStyled/>
            <Box sx={{ display: 'flex' }}>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
              >
                <ProjectVariables/>
                <Box mt={5}>
                  <DocumentVariables/>
                </Box>
                <Box mt={5} sx={{ height: '100vh' }}>
                  <DocumentPreview/>
                </Box>
              </Box>
            </Box>
          </Fragment>
        )}
      </ProjectsPageContentStyled>
      {treeState.error &&
        <ErrorBox
          error={treeState.error}
        >
          <Button
            variant="contained"
            sx={{ maxWidth: '75%' }}
            onClick={() => {
              dispatch(setError(undefined))
              dispatch(loadProjects())
              dispatch(loadConvertedExtensions())
            }
            }
          >
            Попробовать снова
          </Button>
        </ErrorBox>
      }
    </Layout>
  )
}

export default ProjectsPage
