import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { RightDto } from '../../infrastructure/api/administration/contracts/RightDto'
import { RootState } from '../../app/store'
import { loadRole, addUsers, removeUser, saveChanges, deleteRole } from './roleActions'
import { UserDto } from '../../infrastructure/api/administration/contracts/UserDto'
import { RoleDto } from '../../infrastructure/api/administration/contracts/RoleDto'

export interface EditRoleState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  id?: number;
  name: string;
  editName: string;
  users: UserInRoleItem[];
  rights: Right[];
  pageError?: SerializedError;
  actionError?: SerializedError;
  openAddUserDialog: boolean;
  openEditRightsDialog: boolean;
  openConfirmDeleteDialog: boolean;
  backUrl?: string;
}

export interface UserInRoleItem {
  status: 'idle' | 'saving';
  id: string;
  userName: string;
}

export interface Right {
  rightId: number;
  name: string;
  description?: string;
  grouping?: string;
  users?: UserDto[];
  roles?: RoleDto[];
}

const initialState: EditRoleState = {
  status: 'loading',
  name: '',
  editName: '',
  users: [],
  rights: [],
  openAddUserDialog: false,
  openEditRightsDialog: false,
  openConfirmDeleteDialog: false,
}

export const editRoleSlice = createSlice(
  {
    name: 'edit-role',
    initialState,
    reducers: {
      showActionError: (state, action: PayloadAction<Error>) => {
        state.actionError = action.payload
      },
      setPageError: (state, action: PayloadAction<Error | undefined>) => {
        state.actionError = action.payload
      },
      hideActionError: (state) => {
        state.actionError = undefined
      },
      showAddUserDialog: (state) => {
        state.openAddUserDialog = true
      },
      hideAddUserDialog: (state) => {
        state.openAddUserDialog = false
      },
      showEditRightsDialog: (state) => {
        state.openEditRightsDialog = true
      },
      hideEditRightsDialog: (state) => {
        state.openEditRightsDialog = false
      },
      setBackUrl: (state, action: PayloadAction<string>) => {
        state.backUrl = action.payload
      },
      setRights: (state, action: PayloadAction<RightDto[]>) => {
        state.rights = action.payload?.map((r) => {
          return r as Right
        })
      },
      setEditName: (state, action: PayloadAction<string>) => {
        state.editName = action.payload
      },
      showConfirmDeleteDialog: (state) => {
        state.openConfirmDeleteDialog = true
      },
      hideConfirmDeleteDialog: (state) => {
        state.openConfirmDeleteDialog = false
      },
    },
    extraReducers: (build) => {
      build.addCase(loadRole.pending, (state, action) => {
        state.status = 'loading'
      })

      build.addCase(loadRole.fulfilled, (state, action) => {
        state.status = 'idle'
        state.id = action.payload.id
        state.name = action.payload.name
        state.editName = action.payload.name
        state.users = action.payload.users?.map((u) => {
          return { id: u.id, userName: u.userName } as UserInRoleItem
        })
        state.rights = action.payload.rights?.map((r) => {
          return { rightId: r.rightId, name: r.name, description: r.description, grouping: r.grouping } as Right
        })
      })

      build.addCase(loadRole.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })

      build.addCase(addUsers.pending, (state, action) => {
        state.status = 'loading'
      })

      build.addCase(addUsers.fulfilled, (state, action) => {
        state.status = 'idle'

        const newUsers = action.payload.map((u) => {
          return { status: 'idle', ...u } as UserInRoleItem
        })

        state.users = [...state.users, ...newUsers]
      })

      build.addCase(addUsers.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })

      build.addCase(removeUser.pending, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.userId)
        if (user) {
          user.status = 'saving'
        }
      })

      build.addCase(removeUser.fulfilled, (state, action) => {
        state.users = state.users.filter((u) => u.id !== action.meta.arg.userId)
      })

      build.addCase(removeUser.rejected, (state, action) => {
        const user = state.users.find((u) => u.id === action.meta.arg.userId)
        if (user) {
          user.status = 'idle'
        }
        state.actionError = action.error
      })

      build.addCase(saveChanges.pending, (state, action) => {
        state.status = 'saving'
      })

      build.addCase(saveChanges.fulfilled, (state, action) => {
        state.status = 'idle'
        state.name = state.editName
      })

      build.addCase(saveChanges.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })

      build.addCase(deleteRole.pending, (state, action) => {
        state.status = 'loading'
      })

      build.addCase(deleteRole.fulfilled, (state, action) => {
        state.status = 'idle'
      })

      build.addCase(deleteRole.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })
    },
  })

export const {
               setPageError,
               showActionError,
               hideActionError,
               showAddUserDialog,
               hideAddUserDialog,
               showEditRightsDialog,
               hideEditRightsDialog,
               setRights,
               setEditName,
               showConfirmDeleteDialog,
               hideConfirmDeleteDialog,
               setBackUrl,
             } = editRoleSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEditRole = (state: RootState) => state.administration.roles.edit.editRole

export default editRoleSlice.reducer
