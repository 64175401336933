import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { login as loginAction, logout } from './actions'

interface UserSlice {
  id?: string;
  name?: string;
  status: 'idle' | 'loading';
  error?: string;
}

const name = 'user'

const initialState: UserSlice = { status: 'idle' }

const slice = createSlice(
  {
    name,
    initialState,
    reducers: {
      login: (state, action: PayloadAction<{ id: string, username: string }>) => {
        state.id = action.payload.id
        state.name = action.payload.username
      },
    },

    extraReducers: (build) => {
      build.addCase(loginAction.pending, () => {
        return { ...initialState, status: 'loading' }
      })

      build.addCase(loginAction.fulfilled, (state, action) => {
        state.name = action.payload.userName
        state.status = 'idle'
      })

      build.addCase(loginAction.rejected, (state, action) => {
        state.error = action.error.name
        state.status = 'idle'
      })

      build.addCase(logout.pending, () => {
        return { ...initialState, status: 'loading' }
      })

      build.addCase(logout.fulfilled, (state) => {
        state.status = 'idle'
      })

      build.addCase(logout.rejected, (state, action) => {
        state.error = action.error.name
        state.status = 'idle'
      })
    },
  })

export const { login } = slice.actions

export const selectUser = (state: RootState) => state.currentUser.user.user

export default slice.reducer
