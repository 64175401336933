import React, { FunctionComponent, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Grid, Box,
} from '@mui/material'
import { object, string, infer as Infer } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { createRole } from '../../roleActions'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { setCreateRoleDialog, setCreateRoleName, selectRolesList } from '../../rolesListSlice'
import FormInput from '../../../../components/Form/FormInput'

interface Props {
  open: boolean;
}

const commonSx = { mb: 1 }

const createRoleSchema = object(
  {
    roleName: string()
      .min(3, 'Название роли не должно быть менее 3 символов')
      .max(32, 'Название роли должно быть не более 32 символов'),
  })

type CreateRoleInput = Infer<typeof createRoleSchema>;

const CreateRoleDialog: FunctionComponent<Props> = ({ open }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const roleListState = useAppSelector(selectRolesList)

  const methods = useForm<CreateRoleInput>(
    {
      resolver: zodResolver(createRoleSchema),
      mode: 'onChange',
    })

  const {
          control,
          reset,
          handleSubmit,
          formState: { isSubmitSuccessful },
        } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<CreateRoleInput> = (values) => {
    dispatch(createRole(values.roleName))
      .unwrap()
      .then((role) => {
        navigate(`/administration/roles/${role.id}`)
      }).then(() => {
      dispatch(setCreateRoleDialog(false))
    }).catch(() => dispatch(setCreateRoleDialog(false)))
  }

  const renderContent = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <FormInput
            disabled={roleListState.status === 'loading'}
            name="roleName"
            required
            fullWidth
            label="Название"
            defaultValue={'Новая роль'}
            type="roleName"
            sx={commonSx}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Создать новую роль</DialogTitle>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <DialogContent>{renderContent()}</DialogContent>
          <DialogActions>
            <Button
              disabled={roleListState.status === 'loading'}
              onClick={() => {
                dispatch(setCreateRoleDialog(false))
                dispatch(
                  setCreateRoleName(undefined),
                )
                reset()
              }}
              color="primary">
              Отменить
            </Button>
            <Button
              disabled={roleListState.status === 'loading'}
              color="primary"
              variant="contained"
              type="submit"
            >
              Создать
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default CreateRoleDialog
