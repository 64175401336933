import { FunctionComponent } from 'react'
import { Button, Card, CardContent, CardHeader, Divider } from '@mui/material'
import RoleRightsList from './RoleRightsList'
import RoleRightsEditor from './RoleRightsEditor'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import { selectEditRole, showEditRightsDialog, hideEditRightsDialog, setRights } from '../../roleSlice'

const RoleRightsCard: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const renderButtons = () => {
    return (
      roleState.status === 'idle' && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => dispatch(showEditRightsDialog())}
        >
          Редактировать
        </Button>
      )
    )
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Права для роли" action={renderButtons()}/>
      <Divider/>
      <CardContent>
        <RoleRightsList/>
      </CardContent>

      {roleState.openEditRightsDialog && (
        <RoleRightsEditor
          open={roleState.openEditRightsDialog}
          onCancel={() => dispatch(hideEditRightsDialog())}
          onOk={(rights) => {
            dispatch(setRights(rights))
            dispatch(hideEditRightsDialog())
          }}
        />
      )}
    </Card>
  )
}

export default RoleRightsCard
