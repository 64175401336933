import { Skeleton } from '@mui/material';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  urlWithToken: string;
}

const ImageViewer: FunctionComponent<Props> = (props) => {
  const [imgSrc, setImgSrc] = useState(props.urlWithToken || undefined);

  useEffect(() => {
    setImgSrc(undefined);

    const img = new Image();
    img.src = props.urlWithToken;
    img.onload = () => {
      setImgSrc(props.urlWithToken);
    };
  }, [props.urlWithToken]);

  return (
    <Fragment>
      {!imgSrc && <Skeleton variant="rounded" width="100%" height="100%" />}
      {imgSrc && <img {...props} src={imgSrc} alt="" />}
    </Fragment>
  );
};

export default ImageViewer;
