import { Fragment, FunctionComponent, memo } from 'react'
import AuthorizedViewer from '../../preview/AuthorizedViewer'
import { API_ADDRESS } from '../../../infrastructure/api/apiFetch'
import { useAppSelector } from '../../../app/hooks'
import { downloadDialogState } from '../../download/downloadDialogSlice'
import _, { keys } from 'lodash'
import { padWithLeadingZeros } from '../../../helpers/stringHelper'
import { Divider, Grid, Typography } from '@mui/material'
import { RootState } from '../../../app/store'
import { checkRestriction } from '../../user/meSlice'
import { Restrictions_Certificates } from '../../../infrastructure/types/permissions'
import ViewerNotification from '../../preview/ViewerNotification'

const DocumentPreview: FunctionComponent = () => {
  const activeDocument = useAppSelector((state) => state.tree.activeDocument)
  const dialogState = useAppSelector(downloadDialogState)

  const haveApprovedDocumentsRight = useAppSelector(
    (root: RootState) =>
      checkRestriction(root.currentUser.user.me, Restrictions_Certificates, false.toString()),
  )

  if (!activeDocument) {
    return <Fragment/>
  }

  const arrayForSort = [...dialogState.certificates]
  arrayForSort.sort((a, b) => {
    if (!a.revision || !b.revision) {
      return 0
    }
    const va = +a.revision
    const vb = +b.revision
    if (va < vb) {
      return -1
    }
    if (va > vb) {
      return 1
    }
    return 0
  })
  const groups = _.groupBy(arrayForSort, (c) => +c.revision)
  let src = ''
  if (arrayForSort.length > 0) {
    const group = groups[keys(groups).length - 1]
    const rev = padWithLeadingZeros(+group, 2)

    if (dialogState.certificates && dialogState.certificates.length > 0) {
      src = `${API_ADDRESS}/api/projects/${activeDocument.projectId}/items/${activeDocument.data.fileId}/revision/${arrayForSort[arrayForSort.length - 1].revision}/file`
    } else {
      src = `${API_ADDRESS}/api/projects/${activeDocument.projectId}/items/${activeDocument.data.fileId}/approved/file`
    }
  } else {
    src = `${API_ADDRESS}/api/projects/${activeDocument.projectId}/items/${activeDocument.data.fileId}/approved/file`
  }

  if (activeDocument.data.certificateVersion && dialogState.certificates.length === 0 && !haveApprovedDocumentsRight) {
    return (
      <Fragment>
        <ViewerNotification
          text={'Предпросмотр невозможен в данной конфигурации настроек'}
        />
      </Fragment>
    )
  }

  return (
    dialogState.certificates && <AuthorizedViewer
      contentType={activeDocument.data.mimeType ?? ''}
      fileName={activeDocument.data.fileName ?? ''}
      src={src}
      style={{ maxWidth: '100%', maxHeight: '100%' }}
    />
  )
}

export default memo(DocumentPreview)
