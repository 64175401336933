import { createSlice, SerializedError, PayloadAction } from '@reduxjs/toolkit'
import { MeDto } from '../../infrastructure/api/me/types/MeDto'
import { loadMe } from './meActions'
import { RootState } from '../../app/store'
import { UserDto } from '../../infrastructure/api/administration/contracts/UserDto'
import { authUpdateUserAction } from '../administration/authUserActions'

export interface MeState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  me?: MeDto;
  editUser?: UserDto;
  isEditActive: boolean;
  actionError?: SerializedError;
  pageError?: SerializedError;
}

const initialState: MeState = {
  status: 'idle',
  isEditActive: false,
}

export const meSlice = createSlice(
  {
    name: 'me-slice',
    initialState,
    reducers: {
      setEditUserName: (state, action: PayloadAction<string>) => {
        if (state.editUser) {
          state.editUser.userName = action.payload
        }
      },
      setEditEmail: (state, action: PayloadAction<string>) => {
        if (state.editUser) {
          state.editUser.email = action.payload
        }
      },
      setEditFullName: (state, action: PayloadAction<string>) => {
        if (state.editUser) {
          state.editUser.fullName = action.payload
        }
      },
      setEditPosition: (state, action: PayloadAction<string>) => {
        if (state.editUser) {
          state.editUser.position = action.payload
        }
      },
      hidePageError: (state) => {
        state.pageError = undefined
      },
      hideActionError: (state) => {
        state.actionError = undefined
      },
      setEditActive: (state, action: PayloadAction<boolean>) => {
        state.isEditActive = action.payload
      },

    },
    extraReducers: (build) => {
      build.addCase(loadMe.pending, (state, action) => {
        state.status = 'loading'
      })
      build.addCase(loadMe.fulfilled, (state, action) => {
        state.status = 'idle'
        state.me = action.payload
        state.editUser = action.payload.user
      })
      build.addCase(loadMe.rejected, (state, action) => {
        state.status = 'failed'
        state.me = undefined
        state.pageError = action.error
      })
      build.addCase(authUpdateUserAction.pending, (state, action) => {
        state.status = 'saving'
      })
      build.addCase(authUpdateUserAction.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      build.addCase(authUpdateUserAction.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })
    },
  })

export const {
               setEditActive,
               setEditUserName,
               setEditEmail,
               setEditFullName,
               setEditPosition,
               hidePageError,
               hideActionError,
             } = meSlice.actions

export const selectMe = (state: RootState) => state.currentUser.user.me
export const selectSignedUser = (state: RootState) => state.currentUser.user.me.me?.user

export const checkPermission = (state: RootState, permission: string) => {
  const me = state.currentUser.user.me.me
  if (!me || !me.rights) {
    return false
  }

  return me.rights.some(p => p.name === permission)
}

export const checkRestriction = (state: MeState, restriction: string, parameter: string) => {
  const me = state.me
  if (!me || !me.rights) {
    return false
  }

  return me.rights.some(p => p.name === restriction && p.configuringParameter == parameter)
}

export default meSlice.reducer
