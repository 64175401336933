import { FunctionComponent, useState, useEffect, memo } from 'react'
import ReactPlayer from 'react-player'
import PdfViewer from './PdfViewer'
import { getValidToken } from '../../app/storageHelpers'
import { CircularProgress } from '@mui/material'
import ImageViewer from './ImageViewer'
import { useAppSelector } from '../../app/hooks'
import { selectSettings } from '../settings/settingsSlice'
import { getFileNameExtension } from '../../app/stringHelper'
import ViewerNotification from './ViewerNotification'

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  contentType: string;
  fileName: string;
}

const AuthorizedViewer: FunctionComponent<Props> = (props) => {
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { convertedExtensions } = useAppSelector(selectSettings)

  useEffect(() => {
    if (!props.src) {
      return
    } else {
      setLoading(true)
      setError(null)
      getValidToken()
        .then((accessToken) => {
          setToken(accessToken)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          setError(error)
        }).finally(() => {
        setLoading(false)
      })
    }
  }, [props.src])

  const extension = getFileNameExtension(props.fileName) ?? ''

  if (loading) {
    return <CircularProgress/>
  }

  if (error) {
    return <ViewerNotification text={error.message}/>
  }

  const urlWithToken = `${props.src}?token=${token}`

  if (
    convertedExtensions.some(
      (convExt) => convExt.toLowerCase() === extension.toLowerCase(),
    )
  ) {
    return <PdfViewer url={urlWithToken}/>
  }

  if (props.contentType === 'application/pdf') {
    return <PdfViewer url={urlWithToken}/>
  }

  if (props.contentType.match('image/*')) {
    return <ImageViewer urlWithToken={urlWithToken} {...props} />
  }

  if (props.contentType.match('video/*')) {
    return (
      <ReactPlayer
        url={urlWithToken}
        playing
        controls
        width="100%"
        height="100%"
      />
    )
  }

  return (
    <ViewerNotification
      text={'Печатная форма для данного документа не предусмотрена'}
    />
  )
}

export default AuthorizedViewer
