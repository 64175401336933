import { createAsyncThunk } from '@reduxjs/toolkit';
import projectsApi from '../../infrastructure/api/projects/projectsApi';
import { AppDispatch, RootState } from '../../app/store';
import { setActiveDocument, setActiveProject } from './projectsSlice';

export const loadProject = createAsyncThunk('load-projects', () => {
  return projectsApi.getProjects();
});

export const loadProjectContent = createAsyncThunk(
  'load-project-content',
  (payload: { projectId: number }) => {
    return projectsApi.getProjectContent(payload.projectId);
  },
);

export const loadProjectsFilteredContent = createAsyncThunk(
  'load-project-filtered-content',
  (payload: { ids: number[]; searchFilter: string }) => {
    return projectsApi.searchContent(payload.ids, payload.searchFilter);
  },
);

export const loadProjectVariables = createAsyncThunk(
  'load-project-variables',
  (payload: { projectId: number }) => {
    return projectsApi.getProjectVariables(payload.projectId);
  },
);

export const loadDocumentVariables = createAsyncThunk(
  'load-document-variables',
  (payload: { projectId: number; documentId: number; ext?: string }) => {
    return projectsApi.getDocumentVariables(
      payload.projectId,
      payload.documentId,
      payload.ext,
    );
  },
);

export const selectDocument = createAsyncThunk<void, any, { state: RootState }>(
  'select-document',
  (payload: { treeDocumentId: string }, thunkApi) => {
    const state = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const document = state.tree.documents.entities[payload.treeDocumentId];

    dispatch(setActiveDocument(payload.treeDocumentId));

    if (document) {
      dispatch(
        loadDocumentVariables({
          projectId: document.projectId,
          documentId: document.data.fileId,
          ext: document.data.fileName.split('.').pop(),
        }),
      );
    }
  },
);

export const selectProject = (projectId: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(setActiveProject(projectId));
    dispatch(loadProjectVariables({ projectId }));
  };
};
