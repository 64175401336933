import { createAsyncThunk } from '@reduxjs/toolkit'
import authUpdateUser from '../../infrastructure/api/auth/authUsersApi'

export const authUpdateUserAction = createAsyncThunk('users-list/update-user', async (request: {
  id: string,
  userId: string
  userName: string,
  email: string,
  fullName: string,
  position: string
}) => {
  return await authUpdateUser.authUpdateUser(request.id, request)
})

