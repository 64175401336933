import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { Right } from './roleSlice'
import { loadRights, updateRight, deleteRight, createRight } from './rightsActions'
import { getPaths } from './pathsActions'
import { getGroups } from './groupsActions'
import { getStatuses } from './statusesActions'
import { getCertificates } from './certificatesActions'

type ConfiguredRight =
  {
    configuringParameter?: string;
  } & Right;

export enum RestrictionDialogType {
  project,
  group,
  status,
  certificate
}

export interface RightsState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  rights: ConfiguredRight[];
  editRight?: ConfiguredRight;
  paths?: string[];
  groups?: string[];
  statuses?: string[];
  certificates?: string[];
  dialogType?: RestrictionDialogType
  pageError?: SerializedError;
  actionError?: SerializedError;
  openAddRightDialog: boolean;
  openEditRightsDialog: boolean;
  openConfirmDeleteDialog: boolean;
  backUrl?: string;
}

const initialState: RightsState = {
  status: 'loading',
  rights: [],
  openAddRightDialog: false,
  openEditRightsDialog: false,
  openConfirmDeleteDialog: false,
}

export const rightsSlice = createSlice(
  {
    name: 'rights',
    initialState,
    reducers: {
      showActionError: (state, action: PayloadAction<Error>) => {
        state.actionError = action.payload
      },
      hideActionError: (state) => {
        state.actionError = undefined
      },
      hidePageError: (state) => {
        state.pageError = undefined
      },
      setEditRight: (state, action: PayloadAction<ConfiguredRight | undefined>) => {
        state.editRight = action.payload
      },
      setEditRightConfiguringParameter: (state, action: PayloadAction<string>) => {
        if (state.editRight) {
          state.editRight.configuringParameter = action.payload
        }
      },
      showAddRightDialog: (state) => {
        state.openAddRightDialog = true
      },
      hideAddRightDialog: (state) => {
        state.openAddRightDialog = false
      },
      showEditRightsDialog: (state) => {
        state.openEditRightsDialog = true
      },
      hideEditRightsDialog: (state) => {
        state.openEditRightsDialog = false
      },
      setBackUrl: (state, action: PayloadAction<string>) => {
        state.backUrl = action.payload
      },
      showConfirmDeleteDialog: (state) => {
        state.openConfirmDeleteDialog = true
      },
      hideConfirmDeleteDialog: (state) => {
        state.openConfirmDeleteDialog = false
      },
      setAddRightDialogType: (state, action: PayloadAction<RestrictionDialogType | undefined>) => {
        state.dialogType = action.payload
      },
    },
    extraReducers: (build) => {
      build.addCase(loadRights.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(loadRights.fulfilled, (state, action) => {
        state.status = 'idle'
        state.rights = action.payload?.map((r) => {
          return r as ConfiguredRight
        })
      })
      build.addCase(loadRights.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })
      build.addCase(getPaths.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(getPaths.fulfilled, (state, action) => {
        state.status = 'idle'
        state.paths = action.payload
      })
      build.addCase(getPaths.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })
      build.addCase(getStatuses.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(getStatuses.fulfilled, (state, action) => {
        state.status = 'idle'
        state.statuses = action.payload
      })
      build.addCase(getStatuses.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })
      build.addCase(getGroups.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(getGroups.fulfilled, (state, action) => {
        state.status = 'idle'
        state.groups = action.payload
      })
      build.addCase(getGroups.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })
      build.addCase(getCertificates.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(getCertificates.fulfilled, (state, action) => {
        state.status = 'idle'
        state.certificates = action.payload
      })
      build.addCase(getCertificates.rejected, (state, action) => {
        state.status = 'failed'
        state.pageError = action.error
      })
      build.addCase(createRight.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(createRight.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })
      build.addCase(updateRight.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(updateRight.fulfilled, (state, action) => {
        state.status = 'idle'
        const right = state.rights.find((r) => r.rightId === action.payload.rightId)
        if (right) {
          right.description = action.payload.description
          right.configuringParameter = action.payload.configuringParameter
        }
      })
      build.addCase(updateRight.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })
      build.addCase(deleteRight.pending, (state) => {
        state.status = 'loading'
      })
      build.addCase(deleteRight.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })
    },
  })

export const {
               showActionError,
               hideActionError,
               hidePageError,
               setEditRight,
               setEditRightConfiguringParameter,
               showAddRightDialog,
               hideAddRightDialog,
               showEditRightsDialog,
               hideEditRightsDialog,
               showConfirmDeleteDialog,
               hideConfirmDeleteDialog,
               setBackUrl,
               setAddRightDialogType,
             } = rightsSlice.actions

export const selectRights = (state: RootState) => state.administration.rights
export const selectEditRight = (state: RootState) => state.administration.rights.editRight

export default rightsSlice.reducer
