import { styled } from '@mui/material/styles';

export const DrawerDraggerStyled = styled('div')(({ theme }) => ({
  width: '2px',
  cursor: 'ew-resize',
  padding: '4px 0 0',
  borderTop: '1px solid #ddd',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: '#f4f7f9',
}));
