import { FunctionComponent, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography, Stack,
} from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import {
  selectEditRole, showConfirmDeleteDialog, hideActionError, hideConfirmDeleteDialog,
} from '../roleSlice'
import { loadRole, deleteRole } from '../roleActions'
import ErrorDialog from '../../../components/ErrorDialog'
import YesNoDialog from '../../../components/YesNoDialog'
import Layout from '../../../components/Layout/Layout'
import RoleInfo from '../components/roles/RoleInfo'
import ErrorBox from '../../../components/ErrorBox'

const RolePage: FunctionComponent = () => {
  const { roleId } = useParams() as { roleId: string }

  const navigate = useNavigate()
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const loading = roleState.status === 'loading'
  const saving = roleState.status === 'saving'

  useEffect(() => {
    dispatch(loadRole(roleId))
  }, [roleId, dispatch])

  const handleDelete = () => {
    dispatch(showConfirmDeleteDialog())
  }

  return (
    <Layout open={false} setOpen={
      () => {
      }
    } showDrawerButton={false}>
      <Box sx={{ width: '75%', display: 'flex' }} mt={10} mr={'auto'} ml={'auto'}>
        <Stack sx={{ width: '100%' }} direction="column">
          <Box mb={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Tooltip title="Back">
                  <IconButton
                    onClick={() => navigate(roleState.backUrl ?? '/')}
                  >
                    <ArrowBackIos/>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {loading ? (
                    <Skeleton width={50}/>
                  ) : (
                     `Роль ${roleState.name}`
                   )}
                </Typography>
              </Grid>
              <Grid item xs/>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  disabled={loading || saving}
                  onClick={handleDelete}
                >
                  Удалить
                </Button>
              </Grid>
            </Grid>
          </Box>
          <RoleInfo/>
        </Stack>
      </Box>
      {roleState.pageError && (
        <ErrorBox
          error={roleState.pageError}
        >
          <Button
            variant="contained"
            sx={{ maxWidth: '75%' }}
            onClick={() => dispatch(loadRole(roleId))}
          >
            Попробовать снова
          </Button>
        </ErrorBox>
      )}
      <ErrorDialog
        open={roleState.actionError !== undefined}
        title={roleState.actionError?.name || 'Error'}
        text={roleState.actionError?.message ?? ''}
        onOk={() => dispatch(hideActionError())}
      />
      <YesNoDialog
        open={roleState.openConfirmDeleteDialog}
        title="Удалить роль"
        onNo={() => dispatch(hideConfirmDeleteDialog())}
        onYes={() => {
          dispatch(hideConfirmDeleteDialog())
          dispatch(deleteRole(roleId))
            .unwrap()
            .then(() => {
              navigate(`/administration/roles`)
            })
            .catch(() => {
            })
        }}
      >
        {`Вы действительно хотите удалить роль ${roleState.name}`}
      </YesNoDialog>
    </Layout>
  )
}

export default RolePage
