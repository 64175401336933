import { Box, Button, Toolbar, IconButton } from '@mui/material'
import React, { useEffect, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useNavigate } from 'react-router-dom'
import {
  clearCredits,
  getAccessToken,
  setDrawerState,
} from '../../app/storageHelpers'
import { Permissions_Administration_Edit } from '../../infrastructure/types/permissions'
import { RootState } from '../../app/store'
import { StyledAppBarStyled } from '../../features/projects/styledComponents/StyledAppBarStyled'
import { loadMe } from '../../features/user/meActions'
import { logout } from '../../features/user/actions'
import { login } from '../../features/user/userSlice'
import { checkPermission, selectMe, selectSignedUser } from '../../features/user/meSlice'
import { MenuIcon } from '../MenuIcon'
import { MenuOpenedIcon } from '../MenuOpenedIcon'
import { selectTree } from '../../features/projects/projectsSlice'
import { signoutRedirect } from '../../auth/user-service'

interface LayoutProps extends PropsWithChildren {
  open: boolean;
  setOpen: (open: boolean) => void;
  showDrawerButton: boolean
}

const Layout: React.FC<LayoutProps> = ({ open, setOpen, showDrawerButton, children }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setDrawerState(true)
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerState(false)
    setOpen(false)
  }

  const meState = useAppSelector(selectMe)
  const user = useAppSelector(selectSignedUser)
  const treeState = useAppSelector(selectTree)

  useEffect(() => {
    if (!meState.me) {
      dispatch(loadMe())
    }
  }, [])

  const handleLogout = () => {
    const token = getAccessToken()
    dispatch(logout(token))

    clearCredits()
    dispatch(
      login(
        {
          id: '',
          username: '',
        }),
    )

    navigate('/login', { replace: true })
  }

  const canAdmin = useAppSelector(
    (root: RootState) =>
      checkPermission(root, Permissions_Administration_Edit),
  )

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <StyledAppBarStyled
          elevation={0}
          position="fixed"
          open={open}
          sx={{ zIndex: 1400 }}
        >
          <Toolbar>
            {showDrawerButton && <>
          <span>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }), color: '#FFFFFF' }}
            >
          <MenuIcon/>
        </IconButton>
      </span>
              <span>
            <IconButton
              aria-label="close drawer"
              onClick={handleDrawerClose}
              edge="start"
              sx={{
                mr: 2,
                ...(!open && { display: 'none' }),
                color: '#FFFFFF',
              }}
            >
              <MenuOpenedIcon/>
            </IconButton>
          </span></>}
            <Box sx={{ flexGrow: 1 }}/>
            {!treeState.error && <Button
              sx={{ display: { xs: 'flex' }, mr: 1 }}
              size="large"
              color="inherit"
              component={Link}
              to="/"
            >
              Проекты
            </Button>}
            {canAdmin && (
              <>
                <Button
                  sx={{ display: { xs: 'flex' }, mr: 1 }}
                  size="large"
                  color="inherit"
                  component={Link}
                  to="/administration/users"
                >
                  Пользователи
                </Button>
                <Button
                  sx={{ display: { xs: 'flex' }, mr: 1 }}
                  size="large"
                  color="inherit"
                  component={Link}
                  to="/administration/roles"
                >
                  Роли
                </Button>
                <Button
                  sx={{ display: { xs: 'flex' }, mr: 1 }}
                  size="large"
                  color="inherit"
                  component={Link}
                  to="/administration/rights"
                >
                  Права
                </Button>
              </>
            )}
            {user && <Button
              sx={{ display: { xs: 'flex' }, mr: 1 }}
              size="large"
              color="inherit"
              component={Link}
              to="/user"
            >
              {user.userName}
            </Button>}
            <Button
              sx={{ display: { xs: 'flex' }, mr: 1 }}
              size="large"
              color="inherit"
              onClick={() => signoutRedirect({ 'id_token_hint': localStorage.getItem('os_id_token') })}>
              Выход
            </Button>
          </Toolbar>
        </StyledAppBarStyled>
      </Box>
      {children}
    </>
  )
}

export default Layout
