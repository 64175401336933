import React, { FunctionComponent } from 'react'
import { Button, Card, CardContent, CardHeader, Divider } from '@mui/material'
import RoleUsersList from './RoleUsersList'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import { selectEditRole, showAddUserDialog, hideAddUserDialog } from '../../roleSlice'
import SelectUserDialog from '../users/SelectUserDialog'
import { addUsers } from '../../roleActions'

const RoleUsersCard: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const handleAddUser = () => {
    dispatch(showAddUserDialog())
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Пользователи в роли"
        action={
          roleState.status === 'idle' && (
            <Button variant="outlined" color="primary" onClick={handleAddUser}>
              Добавить пользователя
            </Button>
          )
        }
      />
      <Divider/>
      <CardContent>
        <RoleUsersList/>
      </CardContent>

      <SelectUserDialog
        open={roleState.openAddUserDialog}
        exists={roleState.users.map((u) => u.id)}
        onCancel={() => dispatch(hideAddUserDialog())}
        onOk={(users) => {
          dispatch(hideAddUserDialog())
          roleState.id && dispatch(addUsers({
                                              roleId: roleState.id,
                                              users: users.map((u) => u.id),
                                            }))
        }}
      />
    </Card>
  )
}

export default RoleUsersCard
