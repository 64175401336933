import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import {
  SearchProject,
  SearchProjectItem,
} from '../../infrastructure/api/projects/contracts'
import {
  loadProject,
  loadProjectContent,
  loadProjectsFilteredContent,
} from './actions'

export interface Project {
  data: SearchProject;
  loading: boolean;
  documents: Document[];
}

export interface Document {
  data: SearchProjectItem;
  projectId: number;
}

export interface ProjectsState {
  projectsLoadStatus: 'idle' | 'loading' | 'error';
  activeProject?: Project;
  activeDocument?: Document;
  expandAll: boolean;
  isEmpty: boolean;
  filter: string;
  expandedNodes: string[];
  projects: EntityState<Project>;
  documents: EntityState<Document>;
  error?: SerializedError;
}

const projectsAdapter = createEntityAdapter<Project>(
  {
    selectId: (project) => project.data.fileId,
  })

const documentsAdapter = createEntityAdapter<Document>(
  {
    selectId: (document) => `${document.projectId}.${document.data.fileId}`,
  })

const name = 'projects-tree'

const initialState: ProjectsState = {
  projectsLoadStatus: 'loading',
  projects: projectsAdapter.getInitialState(),
  documents: documentsAdapter.getInitialState(),
  expandAll: false,
  filter: '',
  expandedNodes: [],
  isEmpty: false,
}

const slice = createSlice(
  {
    name,
    initialState,
    reducers: {
      setError: (state, action: PayloadAction<Error | undefined>) => {
        state.error = action.payload
      },

      setActiveProject: (state, action: PayloadAction<number>) => {
        state.activeProject = state.projects.entities[action.payload]
      },

      addExpanded: (state, action: PayloadAction<string[]>) => {
        action.payload.forEach((node) => {
          if (!state.expandedNodes.includes(node)) {
            state.expandedNodes.push(node)
          }
        })
      },

      clearExpanded: (state) => {
        state.expandedNodes = []
      },

      setActiveDocument: (state, action: PayloadAction<string | undefined>) => {
        if (typeof action.payload !== 'undefined') {
          const document = state.documents.entities[action.payload]
          state.activeDocument = document

          if (document) {
            state.activeProject = state.projects.entities[document.projectId]
          }
        } else {
          state.activeDocument = undefined
        }
      },
    },
    extraReducers: (build) => {
      build.addCase(loadProject.pending, (state) => {
        return { ...state, projectsLoadStatus: 'loading' }
      })

      build.addCase(loadProject.fulfilled, (state, action) => {
        projectsAdapter.removeAll(state.projects)
        documentsAdapter.removeAll(state.documents)

        state.expandAll = false

        state.projects = projectsAdapter.upsertMany(
          state.projects,
          action.payload.projects.map((project) => {
            return { data: project, documents: [], loading: true } as Project
          }),
        )

        if (action.payload.projects.length === 0) {
          state.isEmpty = true
        } else {
          state.isEmpty = false
        }

        state.projectsLoadStatus = 'idle'
      })

      build.addCase(loadProject.rejected, (state, payload) => {
        return { ...state, projectsLoadStatus: 'error', error: payload.error }
      })

      build.addCase(loadProjectContent.fulfilled, (state, action) => {
        // state.isEmpty = action.payload.items.length === 0;

        if (action.payload.items.length > 0) {
          const documents = action.payload.items.map((i) => {
            return { projectId: action.meta.arg.projectId, data: i } as Document
          })

          documentsAdapter.setMany(state.documents, documents)

          projectsAdapter.updateOne(state.projects, {
            id: action.meta.arg.projectId,
            changes: { documents, loading: false },
          })
        } else {
          projectsAdapter.updateOne(state.projects, {
            id: action.meta.arg.projectId,
            changes: { loading: false },
          })
        }
      })

      build.addCase(loadProjectContent.rejected, (state, action) => {
        projectsAdapter.updateOne(state.projects, {
          id: action.meta.arg.projectId,
          changes: { loading: false },
        })
      })

      build.addCase(loadProjectsFilteredContent.pending, (state) => {
        return { ...state, projectsLoadStatus: 'loading' }
      })

      build.addCase(loadProjectsFilteredContent.fulfilled, (state, action) => {
        if (action.payload.items.length === 0) {
          state.isEmpty = true
        } else {
          state.isEmpty = false
        }

        const documents = action.payload.items.map((i) => {
          return { projectId: i.projectId, data: i } as Document
        })

        documentsAdapter.setMany(state.documents, documents)

        const projectIds = action.payload.items.map((i) => i.projectId)

        const entityIds = state.projects.ids.filter((i) => {
          const id = Number(i)
          if (!projectIds.includes(id)) {
            return i
          }
        })

        projectIds.forEach((id: number) => {
          const projectDocuments = documents.filter((d) => d.projectId === id)

          projectsAdapter.updateOne(state.projects, {
            id: id,
            changes: { documents: projectDocuments, loading: false },
          })
        })

        projectsAdapter.removeMany(state.projects, entityIds)

        state.expandAll = true
        state.projectsLoadStatus = 'idle'
      })
    },
  })

export const {
               setError,
               setActiveProject,
               setActiveDocument,
               addExpanded,
               clearExpanded,
             } = slice.actions

export const { selectAll: selectProjects, selectById: selectProjectById } =
               projectsAdapter.getSelectors((state: RootState) => state.tree.projects)

export const selectTree = (state: RootState) => state.tree

export const {
               selectIds: selectDocumentIds,
               selectById: selectDocumentById,
               selectAll: selectAllDocuments,
             } = documentsAdapter.getSelectors((state: RootState) => state.tree.documents)

export default slice.reducer
