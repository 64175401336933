import { useNavigate  } from "react-router-dom"
import { FC, useEffect } from 'react'
import { signinRedirectCallBack } from './user-service'

const SigninOidc: FC<{}> = ( ) => {
  const navigate  = useNavigate ();
  useEffect(() => {
    async function signinAsync() {
      await  signinRedirectCallBack();
      navigate('/')
    }
    signinAsync();
  }, [])
  return <div>Redirecting login...</div>
}

export default SigninOidc
