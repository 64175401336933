import { Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface VariableContainerProps {
  label: string;
  value?: string;
}

const VariableContainer: FunctionComponent<VariableContainerProps> = (
  props,
) => {
  return (
    <Stack sx={{ marginTop: 2 }} direction={'column'} spacing={1}>
      <Typography variant="valueName">{props.label}</Typography>
      <Typography variant="valueText">{props.value ?? ''}</Typography>
    </Stack>
  );
};

export default VariableContainer;
