import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { loadConvertedExtensions } from './actions';

interface SettingsSlice {
  convertedExtensions: string[];
  status: 'idle' | 'loading' | 'rejected';
  error?: string;
}
const name = 'settings';
const initialState: SettingsSlice = { status: 'idle', convertedExtensions: [] };

const slice = createSlice({
  name,
  initialState,
  reducers: {},

  extraReducers: (build) => {
    build.addCase(loadConvertedExtensions.pending, () => {
      return { ...initialState, status: 'loading' };
    });

    build.addCase(loadConvertedExtensions.fulfilled, (state, action) => {
      state.convertedExtensions = action.payload;
      state.status = 'idle';
    });

    build.addCase(loadConvertedExtensions.rejected, (state, action) => {
      state.error = action.error.name;
      state.status = 'rejected';
    });
  },
});

export const selectSettings = (state: RootState) => state.settings;

export default slice.reducer;
