import { Link, Stack } from '@mui/material';
import { FunctionComponent, PropsWithChildren, useRef } from 'react';
import { apiAuthFetch, apiFetch } from '../infrastructure/api/apiFetch'
import { handleErrors } from '../infrastructure/api/errorHandler';

interface Props {
  method: 'GET' | 'POST';
  url: string;
  filename?: string;
  isDownloading: boolean;
  onDownloading?: () => void;
  onDownloaded?: () => void;
  onError?: (error: Error) => void;
}

const getFileName = (disposition: string): string => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string | null = null;
  if (utf8FilenameRegex.test(disposition)) {
    const exec = utf8FilenameRegex.exec(disposition);
    if (exec && exec.length > 0) {
      fileName = decodeURIComponent(exec[1]);
    }
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName ?? '';
};

const AuthenticatedDownloadLink: FunctionComponent<PropsWithChildren<Props>> = (
  props,
) => {
  const link = useRef<HTMLAnchorElement>(null);

  const handleDownload = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (props.isDownloading) {
      return;
    }

    if (!link?.current) {
      return;
    }

    if (props.onDownloading) {
      props.onDownloading();
    }

    const query = () => {
      const fetch = apiAuthFetch();

      if (props.method === 'POST') {
        return fetch.post(props.url);
      }

      return fetch.get(props.url);
    };

    query()
      .then(handleErrors)
      .then((result) => {
        let filename = '';

        if (result && result.headers) {
          const disposition = result.headers.get('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            filename = getFileName(disposition);
          }
        }

        result.blob().then((blob) => {
          const href = window.URL.createObjectURL(blob);

          if (link.current) {
            link.current.download = filename || props.filename || '';
            link.current.href = href;

            link.current.click();

            if (props.onDownloaded) {
              props.onDownloaded();
            }

            link.current.download = '';
            link.current.href = '';
          }
        });
      })
      .catch((error) => {
        if (props.onError) {
          props.onError(error);
        }
      });
  };

  return (
    <Stack direction="row" justifyItems="center" spacing={1}>
      <Link href="" onClick={(e) => handleDownload(e)} underline="none">
        {props.children}
      </Link>
      <a ref={link} />
    </Stack>
  );
};

export default AuthenticatedDownloadLink;
