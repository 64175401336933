import { handleErrors } from '../errorHandler'
import { API_ADDRESS, apiAuthFetch } from '../apiFetch'

const RIGHTS_API_ADDRESS = `${API_ADDRESS}/api/administration/paths`

export class PathsApi {
  async getPaths() {
    return apiAuthFetch()
      .get(RIGHTS_API_ADDRESS)
      .then(handleErrors)
      .then<string[]>((res) => res.json())
  }

}

const pathsApi = new PathsApi()

export default pathsApi
