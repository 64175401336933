import { createAsyncThunk } from '@reduxjs/toolkit'
import usersApi from '../../infrastructure/api/administration/usersApi'

export const loadUsers = createAsyncThunk('users-list/load', async () => {
  return await usersApi.getUsers()
})

export const updateEnableLogin = createAsyncThunk(
  'users-list/update-enable-login',
  async (data: { id: string; loginEnabled: boolean }) => {
    const { id, loginEnabled } = data
    return await usersApi.updateEnableLogin(id, loginEnabled)
  },
)

export const createUser = createAsyncThunk('users-list/create-user', async (request: {
  userName: string,
  password: string,
  email: string,
  fullName: string,
  position: string
}) => {
  return await usersApi.createUser(request)
})

export const deleteUser = createAsyncThunk('users-list/delete-user', async (id: string) => {
  return await usersApi.deleteUser(id)
})

export const updateUser = createAsyncThunk('users-list/update-user', async (request: {
  id: string,
  userId: string,
  userName: string,
  email: string,
  fullName: string,
  position: string
  password?: string,
}) => {
  return await usersApi.updateUser(request.id, { userId: request.userId, userName: request.userName, email: request.email, fullName: request.fullName, position: request.position, password: request.password })
})

