import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Grow,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FunctionComponent, memo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { login as loginAction } from './actions'
import { useNavigate } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { renewCredits, saveCredits } from '../../app/storageHelpers'
import { selectUser } from './userSlice'
import { wrongCredentialsColor } from '../../theme/theme'
import { loadMe } from './meActions'
import { hidePageError as hideRightPageError } from '../administration/rightsSlice'
import { hidePageError as hideMePageError } from '../user/meSlice'
import { setPageError as setRoleListPageError } from '../administration/rolesListSlice'
import { setPageError as setRolePageError } from '../administration/roleSlice'
import { setError as setProjectsError } from '../projects/projectsSlice'
import { setError as setUserList } from '../administration/usersListSlice'

const LoginPage: FunctionComponent = () => {
  const navigate = useNavigate()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [wrongCredentials, setWrongCredentials] = useState(false)

  const dispatch = useAppDispatch()

  const user = useAppSelector(selectUser)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(hideRightPageError())
    dispatch(hideMePageError())
    dispatch(setRoleListPageError(undefined))
    dispatch(setRolePageError(undefined))
    dispatch(setProjectsError(undefined))
    dispatch(setUserList(undefined))
    dispatch(
      loginAction({
                    userName: username,
                    password: password,
                    rememberMe: true,
                  }),
    )
      .then(unwrapResult)
      .then((obj) => {
        if (obj.userName && obj.token && obj.refreshToken) {
          renewCredits(obj)
          saveCredits(username)
          setWrongCredentials(false)
          navigate('/', { replace: true })
        }
        dispatch(loadMe())
      })
      .catch((obj) => setWrongCredentials(true))
  }

  const handleChangeUsername = (newUsername: string) => {
    setWrongCredentials(false)
    setUsername(newUsername)
  }

  const handleChangePassword = (newPassword: string) => {
    setWrongCredentials(false)
    setPassword(newPassword)
  }

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      {user.status === 'idle' && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          width={1}
          sx={{ minHeight: '95vh' }}
        >
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Stack direction="column" spacing={3}>
              <TextField
                placeholder="username"
                variant="outlined"
                fullWidth
                value={username}
                onChange={(e) => handleChangeUsername(e.target.value)}
              />
              <TextField
                placeholder="password"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => handleChangePassword(e.target.value)}
              />
              {wrongCredentials && (
                <Grow in={wrongCredentials}>
                  <Box
                    p={1}
                    sx={{
                      backgroundColor: wrongCredentialsColor,
                      borderRadius: 1,
                    }}
                  >
                    <Typography color="error">
                      Wrong username or password
                    </Typography>
                  </Box>
                </Grow>
              )}
              <Button type="submit" variant="contained" sx={{ color: 'white' }}>
                Login
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
      {user.status === 'loading' && (
        <Box
          height="100vh"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" color="#616161">
            Loading service
          </Typography>{' '}
          <CircularProgress
            sx={{
              marginLeft: 2,
            }}
            color="success"
          />
        </Box>
      )}
    </form>
  )
}

export default memo(LoginPage)
