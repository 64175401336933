import { Fragment, FunctionComponent } from 'react'
import { Tooltip, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectMe } from '../../../user/meSlice'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { UserListItem, setUpdateUserDialog } from '../../usersListSlice'

interface Props {
  user: UserListItem;
  handleClick: () => void;
}

const DeleteButton: FunctionComponent<Props> = (
  {
    user,
    handleClick,
  }) => {
  const me = useAppSelector(selectMe)
  const dispatch = useAppDispatch()

  const setUserToProcess = () => {
    dispatch(
      setUpdateUserDialog(user),
    )
  }

  if (user.id === me.me?.user.id) {
    return <Fragment/>
  }

  return (
    <Tooltip title={
      <Typography color={'white'} variant={'valueText'}>
        Удалить пользователя
      </Typography>
    }>
      <IconButton aria-label="delete" onClick={() => {
        setUserToProcess()
        handleClick()
      }} disabled={user.roles && user.roles?.length > 0}>
        <DeleteIcon/>
      </IconButton>
    </Tooltip>
  )
}

export default DeleteButton
