import { useNavigate  } from "react-router-dom"
import { FC, useEffect } from 'react'
import { signoutRedirectCallback } from './user-service'

const SignoutOidc: FC<{}> = ( ) => {
  const navigate  = useNavigate ();
  useEffect( () => {
    async function signoutAsync() {
      await  signoutRedirectCallback();
      navigate('/')
    }
    signoutAsync();
  }, [])
  return <div>Redirecting logout...</div>
}

export default SignoutOidc
