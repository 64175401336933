import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Grid, Box, Stack, Select, MenuItem, SelectChangeEvent,
} from '@mui/material'
import { object, string, infer as Infer } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider, SubmitErrorHandler } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import FormInput from '../../../../components/Form/FormInput'
import { selectRights, hideAddRightDialog, RestrictionDialogType } from '../../rightsSlice'
import { createRight, loadRights } from '../../rightsActions'
import InputLabel from '@mui/material/InputLabel'
import { nanoid } from '@reduxjs/toolkit'
import _ from 'lodash'
import FormControl from '@mui/material/FormControl'

interface Props {
  open: boolean;
}

const commonSx = { mb: 1 }

const createRestrictionSchema = object(
  {
    configuringParameter: string()
      .min(2, 'Конфигурирующий параметр не может быть менее 2 символов')
      .max(64, 'Конфигурирующий параметр не может быть более 64 символов'),
    description: string()
      .min(3, 'Описание не может быть менее 3 символов')
      .max(128, 'Описание не может быть более 64 символов'),
  })

type CreateRestrictionInput = Infer<typeof createRestrictionSchema>;

const CreateRestrictionDialog: FunctionComponent<Props> = ({ open }) => {
  const dispatch = useAppDispatch()
  const rightsListState = useAppSelector(selectRights)
  const [description, setDescription] = useState<string | undefined>('')
  const [parameter, setParameter] = useState<string | undefined>('')

  const methods = useForm<CreateRestrictionInput>(
    {
      resolver: zodResolver(createRestrictionSchema),
      mode: 'onChange',
    })

  const {
          reset,
          handleSubmit,
          setValue,
          formState: { isSubmitSuccessful },
        } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ configuringParameter: parameter })
    }
  }, [parameter, isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<CreateRestrictionInput> = (values) => {
    dispatch(createRight({ configuringParameter: values.configuringParameter, description: values.description, type: rightsListState.dialogType ?? RestrictionDialogType.project }))
      .unwrap()
      .then(() => {
        dispatch(hideAddRightDialog())
        dispatch(loadRights())
      }).catch(() => dispatch(hideAddRightDialog()))

  }

  const onErrorHandler: SubmitErrorHandler<CreateRestrictionInput> = (values) => {
  }

  const handleChangeConfiguringParameter = (event: SelectChangeEvent) => {
    setParameter(event.target.value)
    setValue('configuringParameter', event.target.value)
  }

  const renderContent = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Stack direction={'column'}>
            <FormInput
              disabled={rightsListState.status === 'loading'}
              name="description"
              fullWidth
              label="Описание"
              type="description"
              value={description}
              defaultValue={description}
              sx={commonSx}
            />
            <FormInput
              disabled={
                rightsListState.status === 'loading' ||
                rightsListState.dialogType === RestrictionDialogType.group ||
                rightsListState.dialogType === RestrictionDialogType.status ||
                rightsListState.dialogType === RestrictionDialogType.certificate
              }
              name="configuringParameter"
              fullWidth
              label="Конфигурирующий параметр"
              defaultValue={parameter}
              type="configuringParameter"
              value={parameter}
              sx={commonSx}
            />
            {rightsListState.dialogType === RestrictionDialogType.project &&
              <FormControl disabled={rightsListState.status === 'loading'}>
                <InputLabel id="path-label">Пути</InputLabel>
                <Select
                  label="Paths"
                  onChange={handleChangeConfiguringParameter}
                  sx={commonSx}
                >
                  <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                  {
                    _.map(rightsListState.paths, path => (
                      <MenuItem key={nanoid()} value={path}>{path}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {rightsListState.dialogType === RestrictionDialogType.group &&
              <FormControl disabled={rightsListState.status === 'loading'}>
                <InputLabel id="group-label">Группы</InputLabel>
                <Select
                  label="Группы"
                  onChange={handleChangeConfiguringParameter}
                  sx={commonSx}
                >
                  <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                  {
                    _.map(rightsListState.groups, group => (
                      <MenuItem key={nanoid()} value={group}>{group}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {rightsListState.dialogType === RestrictionDialogType.status &&
              <FormControl disabled={rightsListState.status === 'loading'}>
                <InputLabel id="status-label">Статусы</InputLabel>
                <Select
                  label="Статусы"
                  onChange={handleChangeConfiguringParameter}
                  sx={commonSx}
                >
                  <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                  {
                    _.map(rightsListState.statuses, status => (
                      <MenuItem key={nanoid()} value={status}>{status}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {rightsListState.dialogType === RestrictionDialogType.certificate &&
              <FormControl disabled={rightsListState.status === 'loading'}>
                <InputLabel id="status-label">Сертификаты</InputLabel>
                <Select
                  label="Сертификаты"
                  onChange={handleChangeConfiguringParameter}
                  sx={commonSx}
                >
                  <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                  {
                    _.map(rightsListState.certificates, cert => (
                      <MenuItem key={nanoid()} value={cert}>{cert}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
          </Stack>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Создать разрешение</DialogTitle>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <DialogContent>{renderContent()}</DialogContent>
          <DialogActions>
            <Button
              disabled={rightsListState.status === 'loading' || rightsListState.status === 'saving'}
              onClick={() => {
                reset({ description: '', configuringParameter: '' })
                dispatch(hideAddRightDialog())
                setParameter(undefined)
              }}
              color="primary">
              Отменить
            </Button>
            <Button
              disabled={rightsListState.status === 'loading' || rightsListState.status === 'saving'}
              color="primary"
              variant="contained"
              type="submit"
            >
              Создать
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default CreateRestrictionDialog
