import { CircularProgress, Button } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { FunctionComponent } from 'react';
import AuthenticatedDownloadLink from './AuthenticatedDownloadLink';
import { API_ADDRESS } from '../infrastructure/api/apiFetch';
import { DownloadIcon } from './DownloadIcon';

interface Props {
  projectId: number;
  documentId: number;
  filename?: string;
  onError?: (error: Error) => void;
}

const DownloadFile: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloading = () => {
    setIsDownloading(true);
  };

  const handleDownloaded = () => {
    setIsDownloading(false);
  };

  const handleDownloadError = (error: Error) => {
    setIsDownloading(false);
    props.onError && props.onError(error);
  };

  return (
    <AuthenticatedDownloadLink
      method="GET"
      url={`${API_ADDRESS}/api/projects/${props.projectId}/items/${props.documentId}/approved/stream`}
      filename={props.filename}
      isDownloading={isDownloading}
      onDownloading={handleDownloading}
      onDownloaded={handleDownloaded}
      onError={handleDownloadError}
    >
      {isDownloading ? (
        <CircularProgress size={20} sx={{ m: 1 }} />
      ) : (
        <Button
          sx={{ display: 'flex' }}
          variant="contained"
          startIcon={<DownloadIcon />}
        >
          {props.children}
        </Button>
      )}
    </AuthenticatedDownloadLink>
  );
};

export default React.memo(DownloadFile);
