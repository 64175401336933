import {
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { Fragment, FunctionComponent, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectConfigurationById,
  selectConfigurations,
  setActiveConfiguration,
} from '../documentVariablesSlice'
import VariableContainer from './VariableContainer'
import { nanoid } from '@reduxjs/toolkit'
import { CertifiedIcon } from '../../../components/CertifiedIcon'
import DownloadSelector from './DownloadSelector'
import { getCertificates, downloadDialogState } from '../../download/downloadDialogSlice'
import { selectMe, checkPermission, checkRestriction } from '../../user/meSlice'
import {
  Permissions_Documents_Download, Permissions_Administration_Edit, Restrictions_Certificates,
} from '../../../infrastructure/types/permissions'
import { RootState } from '../../../app/store'
import DocumentVariablesLoadingBody from './DocumentVariablesLoadingBody'

const DocumentVariables: FunctionComponent = () => {
  const activeDocument = useAppSelector((state) => state.tree.activeDocument)
  const dialogState = useAppSelector(downloadDialogState)
  const user = useAppSelector(selectMe)
  const variablesStatus = useAppSelector(
    (state) => state.variables.document.status,
  )
  const configurations = useAppSelector(selectConfigurations)
  const activeConfigurationId = useAppSelector(
    (state) => state.variables.document.activeConfiuration,
  )
  const activeConfiguration = useAppSelector((state) =>
                                               selectConfigurationById(state, activeConfigurationId),
  )

  const haveDownloadRight = useAppSelector(
    (root: RootState) =>
      checkPermission(root, Permissions_Documents_Download),
  )

  const haveApprovedDocumentsRight = useAppSelector(
    (root: RootState) =>
      checkRestriction(root.currentUser.user.me, Restrictions_Certificates, false.toString()),
  )

  const dispatch = useAppDispatch()

  const handleChangeTab = (tab: string) => {
    dispatch(setActiveConfiguration(tab))
  }

  useEffect(() => {
    if (activeDocument) {
      dispatch(
        getCertificates(
          {
            projectId: activeDocument.projectId,
            documentId: activeDocument.data.fileId,
          }),
      )
    }
  }, [activeDocument])

  if (!activeDocument) {
    return <Fragment/>
  }

  if (variablesStatus === 'loading') {
    return (
      <DocumentVariablesLoadingBody/>
    )
  }

  if (activeDocument.data.certificateVersion && dialogState.certificates.length === 0 && !haveApprovedDocumentsRight) {
    return (
      <Fragment>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }}/>
        <Grid container spacing={1}>
          <Typography sx={{ marginLeft: 2 }} variant="headerMedium">
            {'Нет контента для отображения.'}
          </Typography>
          <Typography sx={{ marginLeft: 2 }} variant="headerMedium">
            {'Возможно, вы указали не все необходимые опции'}
          </Typography>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }}/>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" color="primary">
              {activeDocument.data.group}
            </Typography>
            <Typography sx={{ marginLeft: 2 }} variant="headerMedium">
              {activeDocument.data.fileName}
            </Typography>
            <Stack
              sx={{ marginLeft: 'auto' }}
              direction="row"
              justifyContent="flex-end"
            >
              {haveDownloadRight && <DownloadSelector
                projectId={activeDocument.projectId}
                fileId={activeDocument.data.fileId}
                approvedVersion={activeDocument.data.approvedVersion}
                certificateVersion={activeDocument.data.certificateVersion}
              />}
            </Stack>
          </Stack>
          <Stack direction="column">
            {activeDocument.data.certificateVersion && (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <CertifiedIcon/>
                <Typography variant="h6" color="primary">
                  {`Сертификат`}
                </Typography>
              </Stack>
            )}

            <VariableContainer
              label={'Состояние'}
              value={activeDocument.data.statusName}
            />
            <VariableContainer
              label={'Текущая версия'}
              value={activeDocument.data.lastRevision.toString()}
            />
            <VariableContainer
              label={'Утвержденная версия'}
              value={activeDocument.data.approvedVersion.toString()}
            />
            {activeDocument.data.certificateVersion && (
              <VariableContainer
                label={'Сертифицированная версия'}
                value={activeDocument.data.certificateVersion?.toString() ?? ''}
              />
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }}/>
          <Tabs
            value={activeConfigurationId}
            onChange={(e, tab) => handleChangeTab(tab)}
          >
            {configurations.map((config) => {
              return (
                <Tab
                  key={config.id}
                  value={config.id.toString()}
                  label={config.name}
                />
              )
            })}
          </Tabs>
        </Grid>

        <Grid item container xs={12}>
          {activeConfiguration &&
            activeConfiguration.variables.map((v) => {
              return (
                <Grid key={nanoid()} item xs={12} md={3}>
                  <Stack
                    sx={{ marginTop: 3.5 }}
                    direction={'column'}
                    spacing={1}
                  >
                    <Typography variant="valueName">{v.name}</Typography>
                    <Typography variant="valueText">{v.value ?? ''}</Typography>
                  </Stack>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default DocumentVariables
