import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { Right } from './roleSlice'
import { RootState } from '../../app/store'
import { loadRights } from './rightsActions'
import { saveRoleRights } from './roleActions'
import { RightDto } from '../../infrastructure/api/administration/contracts/RightDto'

export interface EditRoleRightsState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  systemRights: Right[];
  roleRights: number[];
  dialogError?: SerializedError;
  actionError?: SerializedError;
}

const initialState: EditRoleRightsState = {
  status: 'idle',
  systemRights: [],
  roleRights: [],
}

export const editRoleRightsSlice = createSlice(
  {
    name: 'edit-role-rights',
    initialState,
    reducers: {
      setActionError: (state, action: PayloadAction<Error>) => {
        state.actionError = action.payload
      },
      clearActionError: (state) => {
        state.actionError = undefined
      },
      setRoleRights: (state, action: PayloadAction<number[]>) => {
        state.roleRights = [...action.payload]
      },
      toggleRoleRight: (state, action: PayloadAction<number>) => {
        const currentIndex = state.roleRights.indexOf(action.payload) ?? -1
        const newChecked = [...state.roleRights]
        if (currentIndex === -1) {
          newChecked.push(action.payload)
        } else {
          newChecked.splice(currentIndex, 1)
        }

        state.roleRights = [...newChecked]
      },
    },
    extraReducers: (build) => {
      build.addCase(loadRights.pending, (state) => {
        state.status = 'loading'
      })

      build.addCase(loadRights.fulfilled, (state, action) => {
        state.status = 'idle'

        state.systemRights = action.payload.map((r: RightDto) => {
          return { rightId: r.rightId, name: r.name, description: r.description, grouping: r.grouping } as Right
        })
      })

      build.addCase(loadRights.rejected, (state, action) => {
        state.status = 'failed'
        state.dialogError = action.error
      })

      build.addCase(saveRoleRights.pending, (state) => {
        state.status = 'saving'
      })

      build.addCase(saveRoleRights.fulfilled, (state) => {
        state.status = 'idle'
      })

      build.addCase(saveRoleRights.rejected, (state, action) => {
        state.status = 'idle'
        state.actionError = action.error
      })
    },
  })

export const { setActionError, setRoleRights, toggleRoleRight, clearActionError } = editRoleRightsSlice.actions
export const selectEditRoleRightsDialog = (state: RootState) => state.administration.roles.edit.editRights

export default editRoleRightsSlice.reducer
