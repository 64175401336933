import { TreeItem } from '@mui/lab';
import { Skeleton } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { FunctionComponent, memo } from 'react';

const LoadingTreeItem: FunctionComponent<{ id: string | number | EntityId }> = (
  props,
) => {
  return (
    <TreeItem
      key={props.id + '_loading'}
      nodeId={props.id + '_loading'}
      label={<Skeleton />}
    />
  );
};

export default memo(LoadingTreeItem);
