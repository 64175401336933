import { handleErrors } from '../errorHandler'
import { apiAuthFetch, API_ADDRESS } from '../apiFetch'
import { UpdateUserRequest } from './contracts'

const AUTH_USERS_API_ADDRESS = `${API_ADDRESS}/api/auth/users`

export class AuthUsersApi {
  authUpdateUser = (userId: string, updateUserRequest: UpdateUserRequest) => {
    return apiAuthFetch()
      .put(`${AUTH_USERS_API_ADDRESS}/${userId}`, updateUserRequest)
      .then(handleErrors)
      .then(() => Promise.resolve())
  }
}

const authUsersApi = new AuthUsersApi()

export default authUsersApi
