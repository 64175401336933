import { useNavigate  } from "react-router-dom"
import { FC, useEffect } from 'react'
import {  silentRenewCallback } from './user-service'

const SilentRenewOidc: FC<{}> = ( ) => {
  const navigate  = useNavigate ();
  useEffect( () => {
    async function renewSilentAsync() {
      await  silentRenewCallback();
      navigate('/')
    }
    renewSilentAsync();
  }, [])
  return <div>Silent renew...</div>
}

export default SilentRenewOidc
