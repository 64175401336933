import { UserManagerSettings, UserManager, User } from 'oidc-client-ts'
import { setAuthHeader, setIdToken } from './auth-headers'

export const CLIENT_ADDRESS = process.env.REACT_APP_CLIENT_ADDRESS
export const API_ADDRESS = process.env.REACT_APP_API_ADDRESS
export const API_SCOPE = process.env.REACT_APP_API_SCOPE
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

const userManagerSettings: UserManagerSettings = {
  client_id: `${CLIENT_ID}`,
  redirect_uri: `${CLIENT_ADDRESS}/signin-oidc`,
  response_type: 'code',
  scope: `openid profile ${API_SCOPE}`,
  authority: `${API_ADDRESS}/api`,
  automaticSilentRenew: true,
  popup_post_logout_redirect_uri: `${CLIENT_ADDRESS}/signout-oidc`,
  post_logout_redirect_uri: `${CLIENT_ADDRESS}/signout-oidc`,
  silent_redirect_uri: `${CLIENT_ADDRESS}/renew`,
}

function clearStorageOidc() {
  const keys = Object.keys(localStorage)
  keys.forEach((key) => {
    if (key.includes('oidc')) {
      localStorage.removeItem(key)
    }
  })
}

const userManager = new UserManager(userManagerSettings)

export async function loadUser(): Promise<User | null> {
  const user = await userManager.getUser()
  const token = user?.access_token
  const idToken = user?.id_token
  setAuthHeader(token)
  setIdToken(idToken)
  return user
}

export const signinRedirect = () => userManager.signinRedirect()

export const signinRedirectCallBack = () => userManager.signinRedirectCallback()

export const signoutRedirect = async (args?: any) => {
  await userManager.clearStaleState()
  await userManager.removeUser()
  clearStorageOidc()
  return userManager.signoutRedirect(args)
}

export const signoutRedirectCallback = async () => {
  await userManager.clearStaleState()
  await userManager.removeUser()
  clearStorageOidc()
  return userManager.signoutCallback()
}

export const silentRenewCallback = async () => {
  await userManager.signinRedirect()
  return userManager.signinSilentCallback()
}

export default userManager
