import { FunctionComponent, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Box, Grid, Typography, Button, Stack } from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { checkPermission } from '../../user/meSlice'
import { Permissions_Administration_Edit } from '../../../infrastructure/types/permissions'
import { loadRoles } from '../roleActions'
import RolesList from '../components/roles/RolesList'
import { selectRolesList, setCreateRoleDialog } from '../rolesListSlice'
import CreateRoleDialog from '../components/roles/CreateRoleDialog'
import ErrorBox from '../../../components/ErrorBox'

const RolesListPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const rolesListState = useAppSelector(selectRolesList)

  useEffect(() => {
    dispatch(loadRoles())
  }, [dispatch])

  const canAdmin = useAppSelector(
    (root: RootState) =>
      checkPermission(root, Permissions_Administration_Edit),
  )

  const handleAddRole = () => {
    dispatch(setCreateRoleDialog(true))
  }

  return (
    <Layout open={false} setOpen={
      () => {
      }
    } showDrawerButton={false}>
      <Box sx={{ width: '75%', display: 'flex' }} mt={10} mr={'auto'} ml={'auto'}>
        <Stack sx={{ width: '100%' }} direction="column">
          {rolesListState.status === 'idle' && <Grid container direction="row" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Роли</Typography>
            </Grid>
            <Grid item>
              {canAdmin && (
                <Button variant="outlined" onClick={handleAddRole}>
                  Добавить роль
                </Button>
              )}
            </Grid>
          </Grid>}
          <RolesList/>
        </Stack>
      </Box>
      {rolesListState.status === 'failed' && rolesListState.pageError && (
        <ErrorBox
          error={rolesListState.pageError}
        >
          <Button
            sx={{ maxWidth: '75%' }}
            variant="contained"
            onClick={() => dispatch(loadRoles())}>
            Попробовать снова
          </Button>
        </ErrorBox>
      )}
      <CreateRoleDialog open={rolesListState.createRoleDialogOpened}/>
    </Layout>
  )
}

export default RolesListPage
