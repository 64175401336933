import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Skeleton, TreeItem, TreeView } from '@mui/lab'
import React, { FunctionComponent, memo, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { loadProjectContent, selectDocument, selectProject } from '../actions'
import { DocumentPrefix } from './DocumentTreeItem'
import { ProjectPrefix } from './ProjectTreeItem'
import BaseLeafTreeItem, { Title } from './BaseLeafTreeItem'
import { BaseNode } from '../Tree'
import { SxProps } from '@mui/system'
import { selectTree, setActiveDocument } from '../projectsSlice'
import { Stack, Typography, Box } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'

const ProjectsTreeView: FunctionComponent<{
  children: BaseNode[];
  expandAll: boolean;
  sx?: SxProps;
}> = (props) => {
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])

  const dispatch = useAppDispatch()
  const treeState = useAppSelector(selectTree)
  const loading = treeState.projectsLoadStatus === 'loading'

  useEffect(() => {
    if (treeState.expandAll) {
      setExpandedNodes(treeState.expandedNodes)
    }
  }, [treeState.expandAll, treeState.expandedNodes])

  const handleNodeToggle = (
    e: React.SyntheticEvent<Element, Event>,
    nodes: string[],
  ) => {
    const newNodes = nodes.filter((n) => !expandedNodes.includes(n))
    setExpandedNodes(nodes)

    const newProjects = newNodes
      .filter((n) => n.startsWith(ProjectPrefix))
      .map((n) => n.replace(ProjectPrefix, ''))

    const ids = newProjects.map((n) => Number.parseInt(n))

    ids.forEach((n) => dispatch(loadProjectContent({ projectId: n })))
  }

  const handleNodeSelect = (node: string) => {
    if (node.startsWith(ProjectPrefix)) {
      const projectId = node.replace(ProjectPrefix, '')
      const id = Number.parseInt(projectId)
      dispatch(selectProject(id))
      dispatch(setActiveDocument(undefined))
    }

    if (node.startsWith(DocumentPrefix)) {
      const treeDocumentId = node.replace(DocumentPrefix, '')
      dispatch(selectDocument({ treeDocumentId }))
    }
  }

  if (loading) {
    return (
      <Box paddingLeft={2} paddingRight={2} paddingTop={1}>
        <Stack
          spacing={1}
          direction={'column'}
        >
          <Skeleton sx={{ width: '100%' }} variant="rounded"/>
          <Skeleton sx={{ width: '90%' }} variant="rounded"/>
          <Skeleton sx={{ width: '80%' }} variant="rounded"/>
          <Skeleton sx={{ width: '70%' }} variant="rounded"/>
          <Skeleton sx={{ width: '60%' }} variant="rounded"/>
          <Skeleton sx={{ width: '50%' }} variant="rounded"/>
          <Skeleton sx={{ width: '40%' }} variant="rounded"/>
          <Skeleton sx={{ width: '30%' }} variant="rounded"/>
          <Skeleton sx={{ width: '20%' }} variant="rounded"/>
          <Skeleton sx={{ width: '10%' }} variant="rounded"/>
        </Stack>
      </Box>
    )
  }

  if (treeState.isEmpty) {
    return (
      <Stack marginLeft={2} spacing={2}>
        <Typography variant="h6" color="primary">
          Nothing to show
        </Typography>
      </Stack>
    )
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore/>}
      defaultExpandIcon={<ChevronRight/>}
      onNodeToggle={(e, nodes) => handleNodeToggle(e, nodes)}
      onNodeSelect={(e: any, nodes: string) => handleNodeSelect(nodes)}
      sx={props.sx}
      expanded={expandedNodes}
    >
      {treeState.isEmpty && (
        <TreeItem
          key={nanoid()}
          nodeId={nanoid()}
          label={<Title title={'Nothing to show'}/>}
        />
      )}
      {props.children.map((node) => {
        return <BaseLeafTreeItem key={node.id} node={node}/>
      })}
    </TreeView>
  )
}

export default memo(ProjectsTreeView)
