import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  TableBody,
  Table,
  Tooltip, Typography, Stack,
} from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectRolesList } from '../../rolesListSlice'
import LoadingTableBody from '../LoadingTableBody'
import GroupIcon from '@mui/icons-material/Group'
import { FunctionComponent } from 'react'
import { setBackUrl } from '../../roleSlice'

const RightsList: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const rolesListState = useAppSelector(selectRolesList)

  const handleClickRole = (roleId: number) => {
    dispatch(setBackUrl('/administration/roles'))
    navigate(`/administration/roles/${roleId}`)
  }

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        {rolesListState.status === 'loading' && (
          <Table>
            <TableBody>
              <LoadingTableBody rows={5} columns={2}/>
            </TableBody>
          </Table>
        )}
        {rolesListState.status === 'idle' && (
          <List>
            {_.sortBy(rolesListState.roles, (r) => r.name).map((r) => {
              return (
                <ListItem
                  key={r.id}
                  divider
                  button
                  onClick={() => handleClickRole(r.id)}
                >
                  <ListItemText primary={r.name}></ListItemText>
                  <Tooltip
                    title=
                      {r.users.length > 0 ?
                       <Stack direction={'column'} spacing={0.5}>
                         {_.sortBy(r.users, (u) => u.userName)?.map((u) => (
                           <Typography key={u.id} color={'white'} variant={'valueText'}>
                             {u.userName}
                           </Typography>
                         ))}
                       </Stack>
                                          :
                       <Typography color={'white'} variant={'valueText'}>
                         Нет пользователей
                       </Typography>
                      }
                  >
                    <GroupIcon/>
                  </Tooltip>
                </ListItem>
              )
            })}
          </List>
        )}
      </Box>
    </Container>
  )
}

export default RightsList
