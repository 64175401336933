import React from "react";
import { Fragment, FunctionComponent } from "react";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Skeleton,
  Checkbox,
} from "@mui/material";
import _ from "lodash";
import { useAppSelector } from '../../../../app/hooks'
import { selectEditRole } from '../../roleSlice'

const RoleRightsList: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole);

  if (roleState.status === "loading") {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <ListItem key={i} divider>
              <Box sx={{ width: "100%" }}>
                <Skeleton />
              </Box>
            </ListItem>
          );
        })}
      </List>
    );
  }

  const orderedItesm = _.orderBy(roleState.rights, ["grouping", "description"]);
  const groups = _.groupBy(orderedItesm, "grouping");

  return (
    <List>
      {_.map(groups, (group, name) => (
        <Fragment key={name}>
          <ListSubheader color="primary" disableSticky key={name}>
            {name}
          </ListSubheader>
          {group.map((r) => {
            return (
              <ListItem key={r.rightId}>
                <ListItemText primary={r.description} secondary={r.name} />
                <ListItemSecondaryAction>
                  <Checkbox readOnly checked={true} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </Fragment>
      ))}
    </List>
  );
};

export default RoleRightsList;
