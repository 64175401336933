import { Fragment, FunctionComponent } from 'react'
import DownloadFile from '../../../components/DownloadFile'
import DownloadDialog from '../../download/DownloadDialog'
import { Button } from '@mui/material'
import { DownloadIcon } from '../../../components/DownloadIcon'
import {
  open,
} from '../../download/downloadDialogSlice'
import { useAppDispatch } from '../../../app/hooks'

const DownloadSelector: FunctionComponent<{
  projectId: number;
  fileId: number;
  approvedVersion: number;
  certificateVersion?: number;
}> = (props) => {
  const dispatch = useAppDispatch()

  const handleDownloadClick = () => {
    dispatch(
      open(
        {
          projectId: props.projectId,
        }),
    )
  }

  if (props.certificateVersion && props.certificateVersion > 0) {
    return (
      <Fragment>
        <Button
          sx={{ display: 'flex' }}
          variant="contained"
          startIcon={<DownloadIcon/>}
          onClick={handleDownloadClick}
        >
          {`Скачать сертифицированную редакцию...`}
        </Button>
        <DownloadDialog/>
      </Fragment>
    )
  }

  if (props.approvedVersion > 0) {
    return (
      <DownloadFile projectId={props.projectId} documentId={props.fileId}>
        {`Скачать утвержденный документ`}
      </DownloadFile>
    )
  }

  return <Fragment/>
}

export default DownloadSelector
