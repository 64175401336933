import { TextField, TextFieldProps } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type IFormInputProps =
  {
    name: string;
    defaultValue?: string
  } & TextFieldProps;

const FormInput: FC<IFormInputProps> = ({ name, value, defaultValue, ...otherProps }) => {
  const {
          control,
          formState: { errors },
        } = useFormContext()
  return (
    <Controller control={control} name={name} defaultValue={defaultValue} render={({ field }) => {
      return <TextField
        {...otherProps}
        {...field}
        error={!!errors[name]}
        helperText={errors[name]?.message?.toString()}
      />
    }
    }
    />
  )
}

export default FormInput
