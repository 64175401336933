import React, { FunctionComponent, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Grid, Box, TextField, Stack, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import { object, string, infer as Infer } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider, SubmitErrorHandler } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import FormInput from '../../../../components/Form/FormInput'
import {
  selectRights, selectEditRight, setEditRight, hideEditRightsDialog,
  setEditRightConfiguringParameter, RestrictionDialogType,
} from '../../rightsSlice'
import { updateRight } from '../../rightsActions'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import _ from 'lodash'
import { nanoid } from '@reduxjs/toolkit'

interface Props {
  open: boolean;
}

const commonSx = { mb: 1 }

const updateRestrictionSchema = object(
  {
    configuringParameter: string()
      .min(2, 'Конфигурирующий параметр не может быть менее 2 символов')
      .max(64, 'Конфигурирующий параметр не может быть более 64 символов'),
    description: string()
      .min(3, 'Описание не может быть менее 3 символов')
      .max(128, 'Описание не может быть более 64 символов'),
  })

type UpdateRestrictionInput = Infer<typeof updateRestrictionSchema>;

const UpdateRestrictionDialog: FunctionComponent<Props> = ({ open }) => {
  const dispatch = useAppDispatch()
  const rightsListState = useAppSelector(selectRights)
  const editRight = useAppSelector(selectEditRight)

  const methods = useForm<UpdateRestrictionInput>(
    {
      resolver: zodResolver(updateRestrictionSchema),
      mode: 'all',
    })

  const {
          control,
          setValue,
          reset,
          handleSubmit,
          formState: { isSubmitSuccessful },
        } = methods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      dispatch(
        setEditRight(undefined),
      )
    }
  }, [dispatch, reset, isSubmitSuccessful])

  useEffect(() => {
    if (editRight) {
      reset(
        {
          configuringParameter: editRight.configuringParameter,
          description: editRight.description,
        })
    }
  }, [editRight])

  const onSubmitHandler: SubmitHandler<UpdateRestrictionInput> = (values) => {
    if (editRight) {
      dispatch(updateRight({ rightId: editRight.rightId, configuringParameter: values.configuringParameter, description: values.description }))
        .then(() => {
          dispatch(hideEditRightsDialog())
          dispatch(
            setEditRight(undefined),
          )
        }).catch(() => dispatch(hideEditRightsDialog()))
      reset({ configuringParameter: values.configuringParameter, description: values.description })
    }
  }

  const onErrorHandler: SubmitErrorHandler<UpdateRestrictionInput> = (values) => {
  }

  const handleChangeConfiguringParameter = (event: SelectChangeEvent) => {
    dispatch(setEditRightConfiguringParameter(event.target.value))
    setValue('configuringParameter', event.target.value)
  }

  const renderContent = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Stack direction={'column'}>
            <TextField
              disabled
              name="name"
              fullWidth
              label="Название"
              value={editRight?.name ?? ''}
              type="name"
              sx={commonSx}
            />
            <FormInput
              disabled={rightsListState.status === 'loading'}
              name="description"
              fullWidth
              label="Описание"
              defaultValue={editRight?.description ?? ''}
              value={editRight?.description ?? ''}
              type="description"
              sx={commonSx}
            />
            <Stack>
              <FormInput
                disabled={
                  rightsListState.status === 'loading' ||
                  rightsListState.dialogType === RestrictionDialogType.group ||
                  rightsListState.dialogType === RestrictionDialogType.status
                }
                name="configuringParameter"
                fullWidth
                label="Конфигурирующий параметр"
                defaultValue={editRight?.configuringParameter ?? ''}
                value={editRight?.configuringParameter ?? ''}
                type="configuringParameter"
                sx={commonSx}
              />
              {rightsListState.dialogType === RestrictionDialogType.project &&
                <FormControl disabled={rightsListState.status === 'loading'}>
                  <InputLabel id="path-label">Пути</InputLabel>
                  <Select
                    label="Paths"
                    onChange={handleChangeConfiguringParameter}
                    sx={commonSx}
                  >
                    <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                    {
                      _.map(rightsListState.paths, path => (
                        <MenuItem key={nanoid()} value={path}>{path}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              }
              {rightsListState.dialogType === RestrictionDialogType.group &&
                <FormControl disabled={rightsListState.status === 'loading'}>
                  <InputLabel id="group-label">Группы</InputLabel>
                  <Select
                    label="Группы"
                    onChange={handleChangeConfiguringParameter}
                    sx={commonSx}
                  >
                    <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                    {
                      _.map(rightsListState.groups, group => (
                        <MenuItem key={nanoid()} value={group}>{group}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              }
              {rightsListState.dialogType === RestrictionDialogType.status &&
                <FormControl disabled={rightsListState.status === 'loading'}>
                  <InputLabel id="status-label">Статусы</InputLabel>
                  <Select
                    label="Статусы"
                    onChange={handleChangeConfiguringParameter}
                    sx={commonSx}
                  >
                    <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                    {
                      _.map(rightsListState.statuses, status => (
                        <MenuItem key={nanoid()} value={status}>{status}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              }
              {rightsListState.dialogType === RestrictionDialogType.certificate &&
                <FormControl disabled={rightsListState.status === 'loading'}>
                  <InputLabel id="status-label">Сертификаты</InputLabel>
                  <Select
                    label="Сертификаты"
                    onChange={handleChangeConfiguringParameter}
                    sx={commonSx}
                  >
                    <MenuItem sx={{ display: 'none' }} key={nanoid()} value={undefined}></MenuItem>
                    {
                      _.map(rightsListState.certificates, cert => (
                        <MenuItem key={nanoid()} value={cert}>{cert}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              }
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Обновить разрешение</DialogTitle>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
        >
          <DialogContent>{renderContent()}</DialogContent>
          <DialogActions>
            <Button
              disabled={rightsListState.status === 'loading' || rightsListState.status === 'saving'}
              onClick={() => {
                dispatch(hideEditRightsDialog())
                dispatch(
                  setEditRight(undefined),
                )
              }}
              color="primary">
              Отменить
            </Button>
            <Button
              disabled={rightsListState.status === 'loading' || rightsListState.status === 'saving'}
              color="primary"
              variant="contained"
              type="submit"
            >
              Обновить
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default UpdateRestrictionDialog
