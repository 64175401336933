import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import projectsApi from '../../infrastructure/api/projects/projectsApi'
import { Certifcate } from '../../infrastructure/api/projects/contracts'

const name = 'download-dialog'

interface DownloadDialogState {
  open: boolean;
  status: 'prepare' | 'completed' | 'error';
  fetchStatus: 'idle' | 'pending' | 'rejected';
  certificates: Certifcate[];
  error?: SerializedError;
}

const initialState: DownloadDialogState = {
  open: false,
  certificates: [],
  status: 'completed',
  fetchStatus: 'idle',
}

export const getCertificates = createAsyncThunk(
  `${name}/getCertificates`,
  async (payload: { projectId: number; documentId: number }) => {
    return await projectsApi.getDocumentCertificates(
      payload.projectId,
      payload.documentId,
    )
  },
)

const slice = createSlice(
  {
    name,
    initialState,
    reducers: {
      open: (state, action: PayloadAction<{ projectId: number }>) => {
        return {
          ...initialState,
          open: true,
          certificates: state.certificates,
          projectId: action.payload.projectId,
        }
      },

      close: (state) => {
        return { ...state, open: false }
      },
    },
    extraReducers: (build) => {
      build.addCase(getCertificates.pending, (state) => {
        return { ...state, fetchStatus: 'pending' }
      })

      build.addCase(getCertificates.fulfilled, (state, action) => {
        return {
          ...state,
          status: 'completed',
          certificates: action.payload.itemCertificates,
        }
      })

      build.addCase(getCertificates.rejected, (state, payload) => {
        return { ...state, status: 'error', error: payload.error }
      })
    },
  })

export const { open, close } = slice.actions
export const downloadDialogState = (state: RootState) => state.downloadDialog
export default slice.reducer
