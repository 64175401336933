import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { loadDocumentVariables } from "./actions";

const configurationsAdapter = createEntityAdapter<Configuration>({
  selectId: (configuration) => configuration.id,
});

interface DocumentVariablesState {
  status: "idle" | "loading";
  configurations: EntityState<Configuration>;
  activeConfiuration: string;
}

interface Configuration {
  id: number;
  name: string;
  variables: Variable[];
}

interface Variable {
  id: number;
  name: string;
  value: string;
}

const name = "document-variables";

const initialState: DocumentVariablesState = {
  status: "idle",
  configurations: configurationsAdapter.getInitialState(),
  activeConfiuration: "",
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setActiveConfiguration: (state, action: PayloadAction<string>) => {
      state.activeConfiuration = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(loadDocumentVariables.pending, () => {
      return { ...initialState, status: "loading" };
    });

    build.addCase(loadDocumentVariables.fulfilled, (state, action) => {
      const configurations = action.payload.configurations.map((config) => {
        return {
          id: config.id,
          name: config.name,
          variables: config.variables.map((v) => {
            return {
              id: v.variableId,
              name: v.variableName,
              value: v.valueText,
            } as Variable;
          }),
        } as Configuration;
      });

      configurationsAdapter.setMany(state.configurations, configurations);

      state.activeConfiuration =
        configurations.length === 0 ? "" : configurations[0].id.toString();
      state.status = "idle";
    });
  },
});

export const {
  selectAll: selectConfigurations,
  selectById: selectConfigurationById,
} = configurationsAdapter.getSelectors(
  (state: RootState) => state.variables.document.configurations
);

export const { setActiveConfiguration } = slice.actions;

export default slice.reducer;
