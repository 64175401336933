import { createAsyncThunk } from '@reduxjs/toolkit'
import rolesApi from '../../infrastructure/api/administration/rolesApi'
import rightsApi from '../../infrastructure/api/administration/rightsApi'

export const saveRoleRights = createAsyncThunk(
  'edit-role-rights/save',
  async (data: { roleId: number | string; rights: number[] }) => {
    return await rolesApi.saveRights(data.roleId, data.rights)
  },
)

export const loadRole = createAsyncThunk('edit-role/load', async (roleId: number | string) => {
  return await rolesApi.getRole(roleId)
})

export const addUsers = createAsyncThunk(
  'edit-role/add-users',
  async (data: { roleId: number | string; users: string[] }) => {
    return await rolesApi.addUsers(data.roleId, data.users)
  },
)

export const removeUser = createAsyncThunk(
  'edit-role/remove-user',
  async (data: { roleId: number | string; userId: string }) => {
    await rolesApi.removeUser(data.roleId, data.userId)
  },
)

export const saveChanges = createAsyncThunk(
  'edit-role/save-changes',
  async (data: { roleId: number | string; name: string }) => {
    await rolesApi.updateRole(data.roleId, { name: data.name })
  },
)

export const deleteRole = createAsyncThunk('edit-role/delete-role', async (roleId: number | string) => {
  await rolesApi.deleteRole(roleId)
})

export const loadRoles = createAsyncThunk('roles-list/load', async () => {
  return await rolesApi.getRoles()
})

export const createRole = createAsyncThunk('roles-list/create', async (roleName: string) => {
  return await rolesApi.createRole(roleName)
})
