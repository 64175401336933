import { combineReducers } from '@reduxjs/toolkit'
import usersList from '../administration/usersListSlice'
import rolesList from '../administration/rolesListSlice'
import editRole from '../administration/roleSlice'
import rights from '../administration/rightsSlice'
import selectUsers from '../administration/selectRoleUsersSlice'
import editRights from '../administration/editRoleRightsSlice'

export const administration = combineReducers(
  {
    users: combineReducers(
      {
        list: usersList,
      }),
    roles: combineReducers(
      {
        list: rolesList,
        edit: combineReducers(
          {
            editRole,
            selectUsers,
            editRights,
          }),
      }),
    rights: rights,
  })
