import { Fragment, FunctionComponent } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Skeleton,
  TextField, Stack,
} from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import {
  selectMe, setEditUserName, setEditActive, setEditEmail, setEditFullName, setEditPosition,
} from '../../../user/meSlice'
import { isEmptyOrSpaces } from '../../../../helpers/stringHelper'
import ErrorBox from '../../../../components/ErrorBox'
import { loadMe } from '../../../user/meActions'
import { UserDto } from '../../../../infrastructure/api/administration/contracts/UserDto'
import { authUpdateUserAction } from '../../authUserActions'

const usersEquals = (user?: UserDto, editUser?: UserDto): boolean => {
  if (!user || !editUser) {
    return false
  }
  if (user.id !== editUser.id) {
    return false
  }
  if (user.userName !== editUser.userName) {
    return false
  }
  if (user.email !== editUser.email) {
    return false
  }
  if (user.fullName !== editUser.fullName) {
    return false
  }
  if (user.position !== editUser.position) {
    return false
  }
  return true
}

const UserInfo: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const meState = useAppSelector(selectMe)

  const idle = meState.status === 'idle'
  const loading = meState.status === 'loading'
  const saving = meState.status === 'saving'
  const failed = meState.status === 'failed'
  const isEditActive = meState.isEditActive

  const nameError = isEmptyOrSpaces(meState.editUser?.userName)
  const emailError = isEmptyOrSpaces(meState.editUser?.email)
  const fullNameError = isEmptyOrSpaces(meState.editUser?.fullName)
  const positionError = isEmptyOrSpaces(meState.editUser?.position)

  const handleSaveChanges = () => {
    if (meState.me && meState.editUser) {
      if (!nameError && !emailError && !fullNameError && !positionError) {
        dispatch(authUpdateUserAction(
          {
            id: meState.me.user.id,
            userId: meState.me.user.id,
            userName: meState.editUser.userName ?? '',
            email: meState.editUser.email ?? '',
            fullName: meState.editUser.fullName ?? '',
            position: meState.editUser.position ?? '',
          },
        ))
          .then(() => dispatch(setEditActive(false)))
          .catch(() => dispatch(setEditActive(false)))
      }
    }
  }

  const handleCancelEdit = () => {
    dispatch(setEditActive(false))
  }

  const handleActivateEdit = () => {
    dispatch(setEditActive(true))
  }

  return (
    <Box mt={4}>
      <Container maxWidth="md">
        {(idle || loading || saving) && (
          <Fragment>
            <Paper>
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Stack spacing={2} direction={'column'}>
                      {loading ? (
                        <Skeleton width={100}/>
                      ) : (
                         <TextField
                           variant="outlined"
                           label="Имя пользователя"
                           error={nameError}
                           disabled={!isEditActive}
                           value={meState.editUser?.userName ?? ''}
                           defaultValue={meState.me?.user.userName ?? ''}
                           onChange={(e) =>
                             dispatch(setEditUserName(e.target.value))
                           }
                         />
                       )}
                      {loading ? (
                        <Skeleton width={100}/>
                      ) : (
                         <TextField
                           variant="outlined"
                           label="Почта"
                           error={emailError}
                           disabled={!isEditActive}
                           value={meState.editUser?.email ?? ''}
                           defaultValue={meState.me?.user.email ?? ''}
                           onChange={(e) =>
                             dispatch(setEditEmail(e.target.value))
                           }
                         />
                       )}
                      {loading ? (
                        <Skeleton width={100}/>
                      ) : (
                         <TextField
                           variant="outlined"
                           label="Полное имя"
                           error={fullNameError}
                           disabled={!isEditActive}
                           value={meState.editUser?.fullName ?? ''}
                           defaultValue={meState.me?.user.fullName ?? ''}
                           onChange={(e) =>
                             dispatch(setEditFullName(e.target.value))
                           }
                         />
                       )}
                      {loading ? (
                        <Skeleton width={100}/>
                      ) : (
                         <TextField
                           variant="outlined"
                           label="Должность"
                           error={positionError}
                           disabled={!isEditActive}
                           value={meState.editUser?.position ?? ''}
                           defaultValue={meState.me?.user.position ?? ''}
                           onChange={(e) =>
                             dispatch(setEditPosition(e.target.value))
                           }
                         />
                       )}
                    </Stack>

                  </Grid>
                  <Grid item>
                    {(idle || saving) && isEditActive && (
                      <Stack spacing={2} direction={'row'}>
                        <Button
                          variant="contained"
                          disabled={saving || usersEquals(meState.me?.user, meState.editUser)}
                          onClick={handleSaveChanges}
                        >
                          {saving ? (
                            <CircularProgress size={20}/>
                          ) : (
                             'Сохранить изменения'
                           )}
                        </Button>
                        <Button
                          variant="contained"
                          disabled={saving}
                          onClick={handleCancelEdit}
                        >
                          {saving ? (
                            <CircularProgress size={20}/>
                          ) : (
                             'Отменить'
                           )}
                        </Button>
                      </Stack>
                    )}
                    {idle && !isEditActive && (
                      <Button
                        variant="contained"
                        onClick={handleActivateEdit}
                      >
                        Изменить
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Fragment>
        )}
      </Container>
    </Box>
  )
}

export default UserInfo
