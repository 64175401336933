import {
  Grid,
  Typography, Skeleton,
} from '@mui/material'
import { FunctionComponent } from 'react'

const DocumentVariablesLoadingBody: FunctionComponent = () => {

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Typography>
          <Skeleton/>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          <Skeleton/>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <Skeleton/>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          <Skeleton/>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          <Skeleton/>
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          <Skeleton/>
        </Typography>
      </Grid>
    </Grid>
  )

}

export default DocumentVariablesLoadingBody
